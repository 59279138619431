import {
  State,
  StoreVerifyAccount,
} from './user.models';
import { StoreStatusTypes } from 'common/constants/store';

export const MODULE_NAME = 'user';

export const INIT_CONTACT_XPERSITY: StoreVerifyAccount = Object.freeze({
  isVerifingAccount:          false,
  verifyAccountStatus:        StoreStatusTypes.Undefined,
  verifyAccountErrorMessage:  '',
} as StoreVerifyAccount);

export const INIT_STATE: State = Object.freeze({
  ...INIT_CONTACT_XPERSITY,
});