import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { HelmetTitle } from 'common/libs/helmet';
import { ROUTELIST } from 'routes';
import Layout from 'components/Layout';
import { actionVerifyExpert } from 'store/user';
import { StateFromStore } from 'store/models';
import { StoreStatusTypes } from 'common/constants/store';
import { OwnActions, Props, State } from './VerifyExpert.models';
import { INIT_PROPS, INIT_STATE } from './VerifyExpert.constants';
import { Processing, Thanks, Invalid, Error } from './VerifyExpert.views';
import styles from './VerifyExpert.module.scss';

class VerifyExpert extends React.Component<Props, State> {
  static defaultProps: Props = INIT_PROPS;
  state: State = INIT_STATE;
  componentDidMount() {
    const key = 'verification';
    const params = new URLSearchParams(this.props.location.search);
    if (params.has(key)) {
      const { doVerify } = this.props;
      doVerify && doVerify(params.get(key));
    } else {
      this.setState({ isMissingKey: true });
    }
  }

  renderContent = () => {
    const { isMissingKey } = this.state;
    const { isVerifingAccount, verifyAccountStatus } = this.props;
    if (isMissingKey) {
      return (<Error />);
    } else if (!isVerifingAccount) {
      switch(verifyAccountStatus) {
        case StoreStatusTypes.Success:
          return (<Thanks />);
        case StoreStatusTypes.Error:
          return (<Invalid />);
      }
    }
    return (<Processing />);
  };

  render() {
    return (
      <React.Fragment>
        <HelmetTitle title={ROUTELIST.VERIFY_EXPERT.NAME} />
        <Layout>
          <Container fluid className={styles.wrapper}>
            { this.renderContent() }
          </Container>
        </Layout>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: StateFromStore) => ({
  isVerifingAccount:          state.user.isVerifingAccount,
  verifyAccountStatus:        state.user.verifyAccountStatus,
});

const mapDispatchToProps = (dispatch): OwnActions => ({
  doVerify: (data: string) => dispatch(actionVerifyExpert(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyExpert);