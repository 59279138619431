import React from 'react';
import styles from './Payment.module.scss';

export const Success = () => {
  return (
    <React.Fragment>
      <p className={styles.symbol}>&nbsp;</p>
      <h1 className={styles.title}>Thank you for subscribing!</h1>
      <p className={styles.description}>You're now subscribed to our insights! You'll hear from us shortly.</p>
    </React.Fragment>
  )
};

export const Cancel = () => {
  return (
    <React.Fragment>
      <p className={styles.symbol}>&nbsp;</p>
      <h1 className={styles.title}>Oops, something went wrong!</h1>
      <p className={styles.description}>something went wrong with the checkout process, please contact us for more information..</p>
    </React.Fragment>
  )
};