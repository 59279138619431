import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import combineReducers from './combineReducers';
import { transformGsheetsData } from './persistTransform'
import { MODULE_NAME as languageModuleName } from './language';
import { MODULE_NAME as gsheetModuleName } from './gsheet';

const persistConfig = {
  storage,
  key: 'xpersity',
  whitelist: [languageModuleName, gsheetModuleName],
  transforms: [transformGsheetsData],
}
const persistedReducers = persistReducer(persistConfig, combineReducers);

export default persistedReducers;
