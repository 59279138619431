import React from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
// import { ROUTELIST } from 'routes';
import { ArticleUnit } from 'components';
import { MediumArticle } from 'models/Article';
import { actionViewArticle } from 'common/libs/article';
import { Props } from './ListArticles.models';
import { INIT_PROPS } from './ListArticles.constants';
import styles from './ListArticles.module.scss';

class ListArticles extends React.Component<Props> {
  static defaultProps: Props = INIT_PROPS;

  // handleOnClickToViewArticle = (article: MediumArticle) => {
  //   const { history } = this.props;
  //   const { slug } = article;
  //   const { URL: url, PARAMS: params } = ROUTELIST.ARTICLE;
  //   const urlTransformed: string = params.reduce((r: string, p: string) => {
  //     if(p === 'slug') { return r.replace(/(:slug)/g, slug); }
  //     return r;
  //   }, url);
  //   // 
  //   history.push(urlTransformed);
  // }

  renderList = (article: MediumArticle) => {
    return (
      <Col key={`article-wrapper-${article.id}`} xs="12" sm="6" lg="4" className={styles.articleWrapper}>
        <ArticleUnit
          key={`article-unit-${article.id}`}
          article={article}
          // onClick={this.handleOnClickToViewArticle}
          onClick={() => actionViewArticle(article)(this.props.history)}
        />
      </Col>
    );
  }

  render() {
    return (
      <Row>
        { this.props.articles.map(this.renderList) }
      </Row>
    );
  }
}

export default withRouter(ListArticles);
