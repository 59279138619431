import { get } from 'lodash';

export const getArticleIDFromSlug = (slug: string): string => {
  const regex = /(?:-)([^-]+$)/;
  return get(regex.exec(slug), '[1]', '');
}

export const getFirstHeadingFromContent = (content: string): string => {
  const regex = /(?:<h3>)(.*?)(?:<\/h3>)/;
  return get(regex.exec(content), '[1]', '');
}

export const isAPaidArticle = (heading: string): boolean => {
  return (!heading.includes('(Free)') && heading.includes('(Paid)'));
}