import { getReducerData } from 'common/libs/reducer';
import { ActionStore, ReducerKeyMapping } from 'models/Store';
import { ActionTypes, State } from './article.models';
import { INIT_STATE } from './article.constants';

export default (state: State = INIT_STATE, action: ActionStore) => {
  switch (action.type) {
    case ActionTypes.GetMediumArticles:
      return getReducerData(state, action, {
        loading:      'isGettingMediumArticles',
        statusType:   'getMediumArticlesStatus',
        errorMessage: 'getMediumArticlesErrorMessage',
      } as ReducerKeyMapping);
    case ActionTypes.GetArticleDetail:
      return getReducerData(state, action, {
        loading:      'isGettingArticleDetail',
        statusType:   'getArticleDetailStatus',
        errorMessage: 'getArticleDetailErrorMessage',
      } as ReducerKeyMapping);
    default:
      return state;
  }
};

