import { getReducerData } from 'common/libs/reducer';
import { ActionStore, ReducerKeyMapping } from 'models/Store';
import { ActionTypes, State } from './gsheet.models';
import { INIT_STATE } from './gsheet.constants';

export default (state: State = INIT_STATE, action: ActionStore) => {
  switch (action.type) {
    case ActionTypes.GetListExperts:
      return getReducerData(state, action, {
        loading:      'isGettingListExperts',
        statusType:   'getListExpertsStatus',
        errorMessage: 'getListExpertsErrorMessage',
      } as ReducerKeyMapping);

    default:
      return state;
  }
};
