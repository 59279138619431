import papaParse from 'papaparse';
import { isEmpty, get, has } from 'lodash';
import moment from 'moment';
import { buildTranslationFromGsheetData } from 'common/libs/gsheet';
import { actionCallAPI } from 'common/libs/action';
import { ActionTypes } from './language.models';
import * as translationServices from 'services/translation';
import { Locale } from 'types/locale';
import { ActionStates } from 'common/constants/store';
import { ActionStore } from 'models/Store';

export const actionUpdateLocale = (newLang: Locale) => (dispatch) => {
  dispatch({
    type:     ActionTypes.UpdateLocale,
    state:    ActionStates.Success,
    payload:  newLang,
  } as ActionStore);
};

export const actionGetTranslation = () => (dispatch) => {
  const actionName = ActionTypes.GetTranslation;
  const asyncAction = translationServices.getTranslation();
  const actions = {
    success: async (response) => {
      if (response.status === 200 && has(response, 'data.data') && !isEmpty(response.data.data)) {
        const { data } = response.data;
        const dataParsed = await papaParse.parse(data, { header: true });
        return {
          translationMessageGroup: buildTranslationFromGsheetData(get(dataParsed, 'data', [])),
          updatedAt: moment().toString(),
        };
      }
      return null;
    },
    error: (response) => {
      const error = response.status;
      return error || response.data.error;
    }
  };

  return dispatch(actionCallAPI(actionName, asyncAction, actions));
};