import axios, {AxiosInstance} from 'axios';
import { HTTP_TIMEOUT } from 'common/constants/env';

const httpClient: AxiosInstance = axios.create();
httpClient.defaults.timeout = HTTP_TIMEOUT;

const defaultHeaders = () => ({
});

export default {
  get: async (url: string, customHeaders = {}) => {
    let result: any | null = null;

    await httpClient
      .get(url, {
        headers: {
          ...defaultHeaders,
          ...customHeaders,
        },
      })
      .then((response) => {
        result = response;
        return result;
      })
      .catch((error) => {
        result = error.response;
      });

    return result;
  },

  post: async (url: string, params: object | any, customHeaders = {}) => {
    let result: any | null = null;

    await httpClient
      .post(url, params, {
        headers: {
          ...defaultHeaders,
          ...customHeaders,
        },
      })
      .then((response) => {
        result = response;
        return result;
      })
      .catch((error) => {
        result = error.response;
      });

    return result;
  },

  put: async (url: string, params: object | any, customHeaders = {}) => {
    let result: any | null = null;

    await httpClient
      .put(url, params, {
        headers: {
          ...defaultHeaders,
          ...customHeaders,
        },
      })
      .then((response) => {
        result = response;
        return result;
      })
      .catch((error) => {
        result = error.response;
      });

    return result;
  },

  patch: async (url: string, params: object | any, customHeaders = {}) => {
    let result: any | null = null;

    await httpClient
      .patch(url, params, {
        headers: {
          ...defaultHeaders,
          ...customHeaders,
        },
      })
      .then((response) => {
        result = response;
        return result;
      })
      .catch((error) => {
        result = error.response;
      });

    return result;
  },

  delete: async (url: string, customHeaders = {}) => {
    let result: any | null = null;

    await httpClient
      .delete(url, {
        headers: {
          ...defaultHeaders,
          ...customHeaders,
        },
      })
      .then((response) => {
        result = response;
        return result;
      })
      .catch((error) => {
        result = error.response;
      });

    return result;
  },
};
