import React from 'react';
import { omit } from 'lodash';
import classnames from 'classnames';
import { Modal as ReactModal, ModalProps } from 'react-bootstrap';
import { Props } from './ModalWrapper.models';
import { INIT_PROPS } from './ModalWrapper.constants';
import styles from './ModalWrapper.module.scss';

export default class ModalWrapper extends React.Component<ModalProps & Props> {
  static defaultProps: Props = INIT_PROPS;

  getModalProps = () => {
    const disallowKeys = ['dialogClassName', 'onHide', ...Object.keys(INIT_PROPS)];
    return omit(this.props, disallowKeys);
  }

  getCloseButton = () => {
    return (
      <button
        type="button"
        onClick={() => this.handleOnModalHideClose()}
        className={classnames(['close', styles.closeButton])}
      >
        <span aria-hidden="true" className="fas fa-times" />
        <span className="sr-only">Close</span>
      </button>
    );
  }

  handleOnModalHideClose = () => {
    const { onHide } = this.props;
    onHide && onHide();
  }
  
  render() {
    return (
      <ReactModal
        centered
        keyboard={false}
        backdrop="static"
        onHide={() => this.handleOnModalHideClose()}
        dialogClassName={classnames([styles.dialog, this.props.dialogClassName])}
        { ...this.getModalProps() }
      >
        <ReactModal.Header>
          <ReactModal.Title as="h5" className="capitalize-first-letter">
            { this.props.title }
          </ReactModal.Title>
          <this.getCloseButton />
        </ReactModal.Header>
          { this.props.children }
      </ReactModal>
    )
  }
}
