import React from 'react';
import { omit } from 'lodash';
import { ModalBody, ModalFooter, Button } from 'react-bootstrap';
import { AutoFormatMessage } from 'common/libs/intl';
import { APP_MESSAGES } from 'common/constants/languages';
import { INIT_PROPS } from './ModalMessage.constants';
import { Props } from './ModalMessage.models';
import ModalWrapper from '../ModalWrapper';
import styles from './ModalMessage.module.scss';

export default class ModalMessage extends React.Component<Props> {
  static defaultProps: Props = INIT_PROPS;
  getModalProps = () => {
    const disallowKeys = [...Object.keys(INIT_PROPS)];
    return omit(this.props, disallowKeys);
  }

  // HANDLES
  

  render() {
    return (
      <ModalWrapper
        title={(<AutoFormatMessage message={APP_MESSAGES.message} />)}
        {...this.getModalProps()}
        dialogClassName={styles.dialog}
        onHide={() => this.props.onHide()}
      >
        <ModalBody>
          { this.props.content }
        </ModalBody>
        <ModalFooter>
          <Button
            size="sm"
            variant="outline-secondary"
            className={styles.closeButton}
            onClick={() => this.props.onHide()}
          >
            <AutoFormatMessage message={APP_MESSAGES.close} />
          </Button>
        </ModalFooter>
      </ModalWrapper>
    )
  }
}
