import { omit, get } from 'lodash';
import { MediumArticle } from 'models/Article';

export const getCoverImage = (content: string): string => {
  const regex = /(?:<img.*?src=")(.*?)(?:".*?>)/;
  return get(regex.exec(content), '[1]', '');
}

export const getSlug = (url: string): string => {
  const regex = /(?:\/\/xpersity-insights\.medium\.com\/|\/xpersity-insights\/|\/@xpersity_insights\/)(.*?)(?:\?)/;
  return get(regex.exec(url), '[1]', '');
}

export const articleMapping = (obj: object) => {
  return {
    articleUrl:   get(obj, 'article_url', ''),
    friendlyUrl:  get(obj, 'friendly_url', ''),
    publishedAt:  get(obj, 'published_at', ''),
    coverImage:   getCoverImage(get(obj, 'content', '')),
    slug:         getSlug(get(obj, 'friendly_url', '')),
    ...omit(obj, ['article_url', 'friendly_url', 'published_at']),
  } as MediumArticle;
}