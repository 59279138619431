import { actionCallAPI } from 'common/libs/action';
import { has, get } from 'lodash';
import * as articleServices from 'services/article';
import { ActionTypes } from './article.models';
import { articleMapping } from './article.utils';

export const actionGetMediumArticles = () => (dispatch) => {
  const actionName = ActionTypes.GetMediumArticles;
  const asyncAction = articleServices.getMediumArticles();
  const actions = {
    success: async (response) => {
      if (get(response, 'status', 0) === 200 && has(response, 'data.data')) {
        return {
          mediumArticles: get(response, 'data.data', []).map(articleMapping),
        };
      }
      return { mediumArticles: [] };
    },
    error: (response) => {
      const error = response.status;
      return error || response.data.error;
    }
  };

  return dispatch(actionCallAPI(actionName, asyncAction, actions));
};

export const actionGetArticle = (id: string = '') => (dispatch) => {
  const actionName = ActionTypes.GetMediumArticles;
  const asyncAction = articleServices.getArticleByID(id);
  const actions = {
    success: async (response) => {
      if (get(response, 'status', 0) === 200 && has(response, 'data.data')) {
        return {
          article: articleMapping(get(response, 'data.data', {})),
        };
      }
      return { article: {} };
    },
    error: (response) => {
      const error = response.status;
      return error || response.data.error;
    }
  };

  return dispatch(actionCallAPI(actionName, asyncAction, actions));
};