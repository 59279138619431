import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Nav } from 'react-bootstrap';
import { actionUpdateLocale } from 'store/language';
import { StateFromStore } from 'store/models';
import { Locale } from 'types/locale';
import { OwnActions, Props } from './LanguageSwitcher.models';
import { INIT_PROPS } from './LanguageSwitcher.constants';
import styles from './LanguageSwitcher.module.scss';

export class LanguageSwitcher extends React.PureComponent<Props> {
  static defaultProps: Props = INIT_PROPS;

  handleOnChangeLocale = (newLang: Locale | null) => {
    if (newLang && this.props.locale !== newLang && typeof this.props.doUpdateLocale === 'function') {
      this.props.doUpdateLocale(newLang);
    }
  };

  render() {
    const { className: wrapperClass } = this.props;
    return (
      <Nav
        activeKey={this.props.locale}
        onSelect={(selectedKey) => this.handleOnChangeLocale(selectedKey as Locale)}
        className={classnames(['flex-row', styles.wrapper, wrapperClass])}
      >
        <Nav.Item>
          <Nav.Link className={styles.navLink} eventKey="en">EN</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className={styles.navLink} eventKey="ko">KOR</Nav.Link>
        </Nav.Item>
      </Nav>
    )
  }
}

const mapStateToProps = (state: StateFromStore) => ({
  locale: state.language.locale,
});

const mapDispatchToProps = (dispatch): OwnActions => ({
  doUpdateLocale: (lang: Locale) => dispatch(actionUpdateLocale(lang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
