import { Props, State } from './ArticleSubscription.models';
import { INIT_FORM_DATA } from './FormInformation';

export const INIT_PROPS: Props = Object.freeze({
} as Props);

export const INIT_STATE: State = Object.freeze({
  formData: INIT_FORM_DATA,
  formError: '',
  isShowMessage: false,
} as State);