import React from 'react';
import { omit } from 'lodash';
import { Button } from 'react-bootstrap';
import { AutoFormatMessage } from 'common/libs/intl';
import { Props } from './Medium.models';
import { INIT_PROPS } from './Medium.constants';

export default class Medium extends React.PureComponent<Props> {
  static defaultProps: Props = INIT_PROPS;

  getProps = () => {
    const disallowKeys = [...Object.keys(INIT_PROPS)];
    return omit(this.props, disallowKeys);
  }

  render() {
    return (
      <Button
        {...this.getProps()}
        href="https://medium.com/xpersity-insights"
        variant="outline-primary"
        target="_blank"
      >
        <i className="fab fa-medium mr-2" />
        <AutoFormatMessage message={this.props.message} />
      </Button>
    )
  }
}
