import axios from 'common/libs/axios';
import {
  SUBSCRIPTION_PRODUCTS,
  SUBSCRIPTION_FREE_REPORT,
  SUBSCRIPTION_CREATE_CHECKOUT_SESSION,
  SUBSCRIPTION_UPDATE_SUBSCRIPTION,
} from 'common/constants/api';

export const getProducts = () => (
  axios.get(SUBSCRIPTION_PRODUCTS)
);

export const subscribeForFreeReport = (request: object = {}) => (
  axios.post(SUBSCRIPTION_FREE_REPORT, request)
);

export const subscribeProduct = (request: object = {}) => (
  axios.post(SUBSCRIPTION_CREATE_CHECKOUT_SESSION, request)
);

export const updateSubscription = (request: object = {}) => (
  axios.post(SUBSCRIPTION_UPDATE_SUBSCRIPTION, request)
);