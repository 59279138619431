import { Locale } from 'types/locale';

export enum DownloadType {
  apple  = 'apple',
  google = 'google',
}

export interface Props {
  locale:       Locale | string,
  className:    string,
  downloadType: DownloadType,
}