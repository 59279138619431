import { createStore } from 'redux';
import { persistStore } from 'redux-persist';
import persistedReducers from './persistedReducers';
import { initialState, composedEnhancers } from './storeConfigs';

const store = createStore(persistedReducers, initialState, composedEnhancers);
const persistor = persistStore(store as any);
export {
  store,
  persistor,
};
