import React from 'react';
import {
  Route,
  Switch,
  // Redirect,
  withRouter,
} from 'react-router-dom';
// import ExternalRedirect from 'components/ExternalRedirect';
// import {
//   NotFound,
//   Home,
//   Terms,
//   Experts,
//   Register,
//   VerifyUser,
//   VerifyExpert,
//   ExpertDetail,
//   PrivacyPolicy,
//   ContactXpersity,
//   RegisterByEmail,
//   RegisterByLinkedIn,
//   Insights,
// } from 'pages';
import * as Pages from 'pages';
import { ROUTELIST } from './constants';

// {/* <Route exact path={ROUTES.SIGN_IN.link} component={PageSignIn} />
// <Route exact path={ROUTES.DASHBOARD.link} component={PageDashboard} /> */}

// <Route exact path={ROUTES.RESUME.URL}>
//   <ExternalRedirect to="https://drive.google.com/file/d/1ZaU4LeuU8XpQqasrNopsN5xd_yBonz4O/view" />
// </Route>

// <Route exact path={ROUTES.LINKED_IN.URL}>
//   <ExternalRedirect to="https://www.linkedin.com/in/trinhquocviet/" />
// </Route>

// <Route path="*" component={NotFound} />

// const routes = () => (
//   <Switch>
//     <Route path="*" component={NotFound} />
//   </Switch>
// );
// const brows = () => (
//   <Switch>
//     <Route path="*" component={NotFound} />
//   </Switch>
// );

// const routes = () => (
//   <BrowserRouter>
//     {withRouter(brows)}
//   </BrowserRouter>
// );

// class Routes extends Component {
//   render = () => {
//     return (
//       <Switch>
//         <Route path={ROUTELIST.DASHBOARD.URL} component={Dashboard} />
//         <Route exact path="/">
//           <Redirect to={{ pathname: ROUTELIST.DASHBOARD.URL }} />
//         </Route>
//         <Route path="*" component={NotFound} />
//       </Switch>
//     );
//   }
// }

// export default withRouter(Routes);

const routes = () => (
  <Switch>
    <Route exact path={ROUTELIST.HOME.URL} component={Pages.Home} />
    <Route path={ROUTELIST.EXPERTS.URL} component={Pages.Experts} />
    <Route exact path={ROUTELIST.EXPERT_DETAIL.URL} component={Pages.ExpertDetail} />
    <Route exact path={ROUTELIST.CONTACT_XPERSITY.URL} component={Pages.ContactXpersity} />
    <Route exact path={ROUTELIST.TERM.URL} component={Pages.Terms} />
    <Route exact path={ROUTELIST.PRIVACY.URL} component={Pages.PrivacyPolicy} />
    <Route exact path={ROUTELIST.EXPERT_REGISTRATION.URL} component={Pages.Register} />
    <Route exact path={ROUTELIST.VERIFY_EXPERT.URL} component={Pages.VerifyExpert} />
    <Route exact path={ROUTELIST.VERIFY_USER.URL} component={Pages.VerifyUser} />
    <Route exact path={ROUTELIST.EXPERT_REGISTRATION_BY_EMAIL.URL} component={Pages.RegisterByEmail} />
    <Route exact path={ROUTELIST.EXPERT_REGISTRATION_BY_LINKEDIN.URL} component={Pages.RegisterByLinkedIn} />
    <Route exact path={ROUTELIST.INSIGHTS_MEDIUM.URL} component={Pages.Insights} />
    <Route exact path={ROUTELIST.ARTICLE.URL} component={Pages.PaidArticleDetail} />
    <Route exact path={ROUTELIST.ARTICLE_SUBSCRIPTION.URL} component={Pages.ArticleSubscription} />
    <Route exact path={ROUTELIST.PAYMENT_STATUS.URL} component={Pages.Payment} />
    <Route path="*" component={Pages.NotFound} />
  </Switch>
);

export default withRouter(routes);
