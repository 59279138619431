import axios from 'common/libs/axios';
import {
  GSHEET_LIST_EXPERTS
} from 'common/constants/api';

export const getListExperts = () => (
  axios.get(GSHEET_LIST_EXPERTS)
);

export const getListExpertsFromBackup = () => (
  axios.get('/list-experts.csv')
);
