import React from 'react';
import { omit } from 'lodash';
import buildUrl from 'build-url';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { ROUTELIST } from 'routes';
import { AutoFormatMessage } from 'common/libs/intl';
import { Props } from './ContactXpersity.models';
import { INIT_PROPS } from './ContactXpersity.constants';

export default class ContactXpersity extends React.PureComponent<Props> {
  static defaultProps: Props = INIT_PROPS;

  getProps = () => {
    const disallowKeys = [...Object.keys(INIT_PROPS)];
    return omit(this.props, disallowKeys);
  }
  
  getUrl = () => {
    const { expertName } = this.props;
    if (expertName.length > 0) {
      return buildUrl(ROUTELIST.CONTACT_XPERSITY.URL, {
        queryParams: { name: expertName },
      });
    }
    return ROUTELIST.CONTACT_XPERSITY.URL;
  }

  render() {
    return (
      <Button
        {...this.getProps()}
        as={Link}
        to={this.getUrl()}
        variant="outline-primary"
      >
        <AutoFormatMessage message={this.props.message} />
      </Button>
    )
  }
}
