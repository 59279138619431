import React from 'react';
import ExpertShortDetail from '../ExpertShortDetail';
import MoreDetail from './MoreDetail';
import { INIT_PROPS } from './ExpertFullDetail.constants';
import { Props } from './ExpertFullDetail.models';

export default class ExpertFullDetail extends React.PureComponent<Props> {
  static defaultProps: Props = INIT_PROPS;
  render() {
    const { expertDetail } = this.props;
    return (
      <div>
        <ExpertShortDetail
          hideReadMore
          hideExpertises
          // hideLocations
          expertDetail={expertDetail}
        />
        <MoreDetail expertDetail={expertDetail} />
      </div>
    )
  }
}
