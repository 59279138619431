import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { HelmetTitle } from 'common/libs/helmet';
import { StateFromStore } from 'store/models';
import { actionGetMediumArticles } from 'store/article';
import { ROUTELIST } from 'routes';
import { Layout } from 'components';
import SectionHero from './SectionHero';
import SectionUseCases from './SectionUseCases';
import SectionDecisions from './SectionDecisions';
import SectionWhoAreOurExperts from './SectionWhoAreOurExperts';
import SectionInsights from './SectionInsights';
import { OwnActions, Props } from './Home.models';
import { INIT_PROPS, MAX_ARTICLES } from './Home.constants';

class Home extends React.Component<Props> {
  static defaultProps: Props = INIT_PROPS;

  componentDidMount() {
    const { isGettingMediumArticles } = this.props;
    if (!isGettingMediumArticles) {
      this.props.doGetListArticles && this.props.doGetListArticles();
    }
  }

  getArticles = () => {
    const { mediumArticles } = this.props;
    if (Array.isArray(mediumArticles) && mediumArticles.length > 0) {
      return mediumArticles.length > 3 ? mediumArticles.splice(0, MAX_ARTICLES) : mediumArticles;
    }
    return [];
  }

  render() {
    return (
      <React.Fragment>
        <HelmetTitle title={ROUTELIST.HOME.NAME} />
        <Layout noHorizontalPadding>
          <Container>
            <SectionHero />
            <SectionInsights articles={this.getArticles()} />
            <SectionWhoAreOurExperts />
            <SectionUseCases />
          </Container>
          <SectionDecisions />
        </Layout>
      </React.Fragment>
    )
  }
}


const mapStateToProps = (state: StateFromStore) => ({
  mediumArticles: state.article.mediumArticles,
  isGettingMediumArticles: state.article.isGettingMediumArticles,
  getMediumArticlesStatus: state.article.getMediumArticlesStatus,
  getMediumArticlesErrorMessage: state.article.getMediumArticlesErrorMessage,
});

const mapDispatchToProps = (dispatch): OwnActions => ({
  doGetListArticles: () => dispatch(actionGetMediumArticles()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
