import React from 'react';
import { Helmet } from "react-helmet";
import { APP_NAME, DEFAULT_LOCALE } from 'common/constants/env';

export const HelmetTitle = ({ title = '' }) => {
  const titleStr = (`${title}`.length > 0)
                  ? `${APP_NAME} - ${title}`
                  : APP_NAME;
  const titleEl = React.createElement('title', {}, titleStr);
  return React.createElement(Helmet, {}, titleEl);
};

export const HelmetLanguage = ({ lang = DEFAULT_LOCALE }) => {
  const html = React.createElement('html', { lang: lang });
  return React.createElement(Helmet, {}, html);
}