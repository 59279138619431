import React from 'react';
// import styles from './PrivacyPolicy.module.scss';

export const Header = ({ title }) => (
  <div className="mb-3">
    <h1 className="mb-0 text-center text-md-left">{ title }</h1>
  </div>
);

// export const Section = ({ id, title, children }) => (
//   <div className={styles.section}>
//     <h4 id={id} className={styles.sectionTitle}>{ title }</h4>
//     <div className={styles.sectionContainer}>
//       { children }
//     </div>
//   </div>
// );