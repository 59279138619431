import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { IntlWrapper } from 'components/WrapperComponents';
import App from './App';
import { store, persistor } from 'store';
import TagManager from 'react-gtm-module';
import { TRACKING_GTM } from 'common/constants/env';
import * as serviceWorker from './serviceWorker';

TagManager.initialize({gtmId: TRACKING_GTM});
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <IntlWrapper>
        <App />
      </IntlWrapper>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
