import { get } from 'lodash';
import { DEFAULT_SUBSCRIPTION_LIST } from './SectionSubscription.constants';

export const generateSubscriptionList = (products: Array<any> = []) => {
  const result = [...DEFAULT_SUBSCRIPTION_LIST];

  products.forEach(p => {
    result.push({
      id: get(p, 'id', ''),
      title: 'Subscription',
      isMostPopular: true,
      isFirstMonthFree: true,
      priceId: get(p, 'prices[0].id', ''),
      price: parseFloat(get(p, 'prices[0].unit_amount_decimal', '0')) * 0.01,
      features: {
        onetime_report: true,
        biweekly_report: true,
        quarterly_podcast: true,
      },
      buttonText: 'Subscribe',
    });
  });

  return result || [];
};
