import {
  State,
  StoreGetProducts,
  StoreSubscribeProduct,
  StoreSubscribeForFreeReport,
  StoreUpdateSubscription,
} from './subscription.models';
import { StoreStatusTypes } from 'common/constants/store';

export const MODULE_NAME = 'subscription';

export const INIT_GET_PRODUCTS: StoreGetProducts = Object.freeze({
  isGettingProducts:        false,
  getProductsStatus:        StoreStatusTypes.Undefined,
  getProductsErrorMessage:  '',
  products:                 [],
} as StoreGetProducts);

export const INIT_SUBSCRIBE_FOR_FREE_REPORT: StoreSubscribeForFreeReport = Object.freeze({
  isSubscribeForFreeReport:           false,
  subscribeForFreeReportStatus:       StoreStatusTypes.Undefined,
  subscribeForFreeReportErrorMessage: '',
} as StoreSubscribeForFreeReport);

export const INIT_SUBSCRIBE_PRODUCT: StoreSubscribeProduct = Object.freeze({
  isSubscribingProduct:         false,
  subscribeProductStatus:       StoreStatusTypes.Undefined,
  subscribeProductErrorMessage: '',
  subscribeSessionId:           '',
  subscribeStripePublicKey:     '',
} as StoreSubscribeProduct);

export const INIT_UPDATE_SUBSCRIPTION: StoreUpdateSubscription = Object.freeze({
  isUpdatingSubscription:         false,
  updateSubscriptionStatus:       StoreStatusTypes.Undefined,
  updateSubscriptionErrorMessage: '',
} as StoreUpdateSubscription);

export const INIT_STATE: State = Object.freeze({
  ...INIT_GET_PRODUCTS,
  ...INIT_SUBSCRIBE_PRODUCT,
  ...INIT_SUBSCRIBE_FOR_FREE_REPORT,
  ...INIT_UPDATE_SUBSCRIPTION,
});