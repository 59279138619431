import { MediumArticle } from 'models/Article';
// import {  } from 'react-router-dom';
import { ROUTELIST } from 'routes';


export const actionViewArticle = (article: MediumArticle) => (history: any) => {
  const { slug } = article;
  const { URL: url, PARAMS: params } = ROUTELIST.ARTICLE;
  const urlTransformed: string = params.reduce((r: string, p: string) => {
    if(p === 'slug') { return r.replace(/(:slug)/g, slug); }
    return r;
  }, url);
  // 
  history.push(urlTransformed);
}
