import React from 'react';
import { has, get, isArray, isString, isObject, isEmpty } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import { GsheetExpertEducation, GsheetExpertExperience } from 'models/GsheetExpert';
import { Props, History as HistoryModel } from './MoreDetail.models';
import { INIT_PROPS, DEFAULT_QUERY_KEYS } from './MoreDetail.constants';
import { normalizeListObjects } from './MoreDetail.utils';
import { AutoFormatMessage } from 'common/libs/intl';
import messages from './MoreDetail.messages';
import styles from './MoreDetail.module.scss';

export default class MoreDetail extends React.PureComponent<Props> {
  static defaultProps: Props = INIT_PROPS;

  renderGroup = (query: string) => {
    const { expertDetail: detail } = this.props;
    const key = `g-${query}`;
    if (has(detail, query) && !isEmpty(detail[query])) {
      return (
        <Col key={key} xs="12" className={styles.group}>
          <h5 className={styles.groupTitle}>
            <AutoFormatMessage message={messages[query]} />
          </h5>
          { this.renderGroupContent(key, get(detail, query, [])) }
        </Col>
      );
    }
    return null;
  }

  renderGroupContent = (parentKey: string, content: string[] | GsheetExpertEducation[] | GsheetExpertExperience[] = []) => {
    if (isArray(content) && content.length > 0) {
      if (isString(content[0])) {
        if (parentKey.includes('media')) {
          return this.renderLinkList(parentKey, content as string[]);
        }
        return this.renderList(parentKey, content as string[]);
      } else if (isObject(content[0])) {
        // @ts-ignore
        return this.renderHistories(parentKey, normalizeListObjects(content));
      }
    }
    return null;
  }

  renderList = (key: string, list: string[]) => {
    return (
      <ul className={styles.listStrings}>
        { list.map((s, i) => (<li key={`${key}-${i}`}>{ s }</li>)) }
      </ul>
    );
  }

  renderLinkList = (key: string, list: string[]) => {
    return (
      <ul className={styles.listStrings}>
        { list.map((s, i) => (
          <li key={`${key}-${i}`}><a href={s} target="_blank" rel="noopener noreferrer">{ s }</a></li>
        )) }
      </ul>
    );
  }

  renderHistories = (key: string, list: HistoryModel[]) => {
    return (
      <div className={styles.historyWrapper}>
        { list.map((o, i) => this.renderHistory(`${key}-${i}`, o)) }
      </div>
    );
  }

  renderHistory = (key: string, h: HistoryModel) => (
    <div key={key} className={styles.history}>
      <h6 className={styles.historyTitle}>{ h.title }</h6>
      <p className={styles.historyDescription}>{ h.description }</p>
      <p className={styles.historyPeriod}>{ h.period }</p>
    </div>
  )

  render() {
    return (
      <Row className={styles.moreDetail}>
        { DEFAULT_QUERY_KEYS.map(this.renderGroup) }
      </Row>
    )
  }
}
