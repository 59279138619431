import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'page.home.sectionUseCases.title',
    defaultMessage: 'Solutions',
  },
  'useCase[0].title': {
    id: 'page.home.sectionUseCases.useCase[0].title',
    defaultMessage: 'Market and company assessment',
  },
  'useCase[1].title': {
    id: 'page.home.sectionUseCases.useCase[1].title',
    defaultMessage: 'Insights',
  },
  'useCase[2].title': {
    id: 'page.home.sectionUseCases.useCase[2].title',
    defaultMessage: 'Investments',
  },
  'useCase[0].content': {
    id: 'page.home.sectionUseCases.useCase[0].content',
    defaultMessage: 'Analyze investment opportunities in a specific space and gain actionable insight with an expert who has real experience and pulse on the ground',
  },
  'useCase[1].content': {
    id: 'page.home.sectionUseCases.useCase[1].content',
    defaultMessage: 'Investment success takes more than due diligence - get insight into expert’s research and perspective as well as diverse point of view from their networks',
  },
  'useCase[2].content': {
    id: 'page.home.sectionUseCases.useCase[2].content',
    defaultMessage: 'Learn about rare investment opportunities in Asia - from angel investing to syndicate offering to secondary markets to pre-IPO financing',
  },
})
