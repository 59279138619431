export const getErrorMessage = (key: string): string => {
  switch(key) {
    case 'firstName':
      return 'First name length must from 1 to 50';
    case 'lastName':
      return 'Last name length must from 1 to 50';
    case 'email':
      return 'Email format is invalid';
    default:
      return '';
  }
}