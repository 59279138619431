import { isObject, isEmpty, has } from 'lodash';
import { IS_PRODUCTION } from 'common/constants/env'
import { ActionStore } from 'models/Store';
import { ActionStates } from 'common/constants/store';
import ERROR from 'common/constants/errors';

const FUNC_PARAMS_ERROR = {
  MISSING_ACTION: '@actions should be object',
  MISSING_FUNC_SUCCESS: '@actions missing key `success`, `success` should be a function will call when asyncAction success',
  ACTION_NAME_MISMATCH: 'ACTION_NAME is mismatch, please check constants/actionTypes.js',
}

export const actionCallAPI = (ACTION_NAME: string, asyncAction, actions) => async (dispatch) => {
  let isAllowed = true;
  // 
  if (!isEmpty(actions) && !isObject(actions)) {
    isAllowed = false;
    if (!IS_PRODUCTION) {
      console.error(FUNC_PARAMS_ERROR.MISSING_ACTION);
    }
  } else if (isObject(actions) && !has(actions, 'success')) {
    isAllowed = false;
    if (!IS_PRODUCTION) {
      console.error(FUNC_PARAMS_ERROR.MISSING_FUNC_SUCCESS);
    }
  }
  // 
  if (isAllowed) {
    dispatch({type: ACTION_NAME, state: ActionStates.Start} as ActionStore);
    try {
      return await asyncAction.then(async (response) => {
        let result: any | null = null;
        if (has(actions, 'success') && typeof actions.success === 'function') {
          result = await actions.success(response);
        }
        if (!result) {
          let error: any | null = null;
          if (has(actions, 'error') && typeof actions.error === 'function') {
            error = await actions.error(response)
          } else {
            const { status, data } = response;
            error =  data.message || data.error || status;
          }
          throw error || ERROR.DEFAULT;
        } else {
          const payload = result;
          dispatch({
            type: ACTION_NAME,
            state: ActionStates.Success,
            payload,
          } as ActionStore);

          return payload;
        }
      })
      .catch(error => {
        if (!IS_PRODUCTION) {
          console.log(error);
        }
        throw error;
      });
    } catch (error) {
      dispatch({
        type: ACTION_NAME,
        state: ActionStates.Failure,
        payload: error.message || error,
      } as ActionStore);

      return null;
    }
  }
};