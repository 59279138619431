import { Locale } from 'types/locale';
import { BadgeImage } from './models';
// 
import enAppleStore from './en/apple-store.svg';
import enGoogleStore from './en/google-play.svg';
import krAppleStore from './kr/apple-store.svg';
import krGoogleStore from './kr/google-play.svg';

const STORE_BADGES = {
  [Locale.en]: {
    apple: enAppleStore,
    google: enGoogleStore,
  } as BadgeImage,
  [Locale.ko]: {
    apple: krAppleStore,
    google: krGoogleStore,
  } as BadgeImage,
};

export default STORE_BADGES;
