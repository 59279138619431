import moment from 'moment';
import { DATETIME_FORMAT } from 'common/constants/dateTime';

export const removeTime = (obj: moment.Moment | Date | string): moment.Moment => {
  return moment(obj).millisecond(0).second(0).minute(0).hour(0);
}

export const getFormattedTime = (timeString: string = ''): string => {
  const obj = moment(timeString);
  return obj.isValid() ? obj.local().format(DATETIME_FORMAT.DATE) : timeString;
}