import React from 'react';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import 'moment/locale/ko';
import 'moment/locale/en-gb';
import { actionGetTranslation, actionUpdateLocale } from 'store/language';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Locale } from 'types/locale';
import { StateFromStore } from 'store/models';
import { HelmetLanguage } from 'common/libs/helmet';
import { INIT_PROPS } from './Intl.constants';
import { Language, OwnActions, Props } from './Intl.models';

class Intl extends React.PureComponent<Props> {
  static defaultProps: Props = INIT_PROPS;

  componentDidMount = () => {
    const { updatedAt } = this.props;
    if (isEmpty(updatedAt) || moment().isAfter(moment(updatedAt).add(5,'minute'))) {
      this.props.doGetTranslation();
    }
    // 
    const language: string | null = new URLSearchParams(window.location.search).get('default_language');
    if (language !== null && !isEmpty(language)) {
      this.updateLocaleByLanguageRequest(language);
    } else {
      this.updateMomentLocale();
    }
  }
  componentDidUpdate = () => this.updateMomentLocale();
  updateMomentLocale = () => {
    const { locale } = this.props;
    if (moment.locale() !== locale) {
      moment.locale(locale === 'en' ? 'en-gb' : locale);
    }
  }

  updateLocaleByLanguageRequest = (language: string) => {
    let locale: Locale | null = null;
    switch(language) {
      case Language.en:
        locale = Locale.en; break;
      case Language.ko:
        locale = Locale.ko; break;
    }
    if (locale !== null && !isEmpty(locale)) {
      this.props.doUpdateLocale(locale);
    }
  }
  
  render() {
    // @ts-ignore
    const { locale, translationMessageGroup } = this.props;
    
    return (
      <IntlProvider
        key={this.props.locale}
        locale={this.props.locale} // @ts-ignore
        messages={get(translationMessageGroup, locale, {})}
        onError={(str) => console.warn(`IntlProvider error: ${str}`)}
      >
        <HelmetLanguage lang={this.props.locale} />
        { this.props.children }
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state: StateFromStore) => ({
  locale: state.language.locale,
  translationMessageGroup: state.language.translationMessageGroup,
  updatedAt: state.language.updatedAt,
});

const mapDispatchToProps = (dispatch): OwnActions => ({
  doGetTranslation: () => dispatch(actionGetTranslation()),
  doUpdateLocale: (lang: Locale) => dispatch(actionUpdateLocale(lang)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Intl);