import buildUrl from 'build-url';
import axios from 'common/libs/axios';
import { ARTICLE_MEDIUM_URL, ARTICLE_DETAIL_URL } from 'common/constants/api';

export const getMediumArticles = () => {
  return axios.get(ARTICLE_MEDIUM_URL);
};

export const getMediumArticlesByCategory = (category: string = '') => {
  const url = buildUrl(ARTICLE_MEDIUM_URL, {queryParams: { category }});
  return axios.get(url);
}

export const getArticleByID = (id: string = '') => {
  const url = buildUrl(ARTICLE_DETAIL_URL.replace(':id', id));
  return axios.get(url);
}