import { Props } from './SectionSubscription.models';

export const INIT_PROPS: Props = Object.freeze({
  products: [],
  articleId: '',
  hideFree: false,
  hideSubscription: false,
  // 
  onClickLink: () => {},
} as Props);

export const FEATURES = {
  onetime_report: 'Report to your inbox',
  biweekly_report: 'Bi-weekly report',
  quarterly_podcast: 'Quarterly podcast',
};

export const DEFAULT_SUBSCRIPTION_LIST = [
  {
    id: '',
    title: 'Free',
    isMostPopular: false,
    isFirstMonthFree: false,
    priceId: '',
    price: 0,
    features: {
      onetime_report: true,
      biweekly_report: false,
      quarterly_podcast: false,
    },
    buttonText: 'Free report'
  },
  // {
  //   id: 'prod_J73qHv7wd9mYWh',
  //   title: 'Subscription',
  //   isMostPopular: true,
  //   isFirstMonthFree: true,
  //   price: 5.99,
  //   features: {
  //     onetime_report: true,
  //     biweekly_report: true,
  //     quarterly_podcast: true,
  //   },
  //   buttonText: 'Subscribe'
  // },
];