export enum ActionStates {
  Start   = 'STARTED',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  Reset   = 'RESET',
};

export enum StoreStatusTypes {
  Undefined = 'undefined',
  Success   = 'success',
  Error     = 'error',
}