import { forEach, has, isArray, isObject, isString, get, isEmpty, reject } from 'lodash';

export interface GsheetExpertEducation {
  university: string,
  major:      string,
  year:       string,
}
const getGsheetExpertEducationKeys = () => ['university', 'major', 'year'];
export const isGsheetExpertEducation = (obj: object) => {
  return getGsheetExpertEducationKeys().reduce((prevResult: boolean, k: string) => {
    return (prevResult && has(obj, k));
  }, true);
}

export interface GsheetExpertExperience {
  company:  string,
  location: string,
  year:     string,
  title:    string,
}
const getGsheetExpertExperienceKeys = () => ['company', 'year', 'title', 'location'];
export const isGsheetExpertExperience = (obj: object) => {
  return getGsheetExpertExperienceKeys().reduce((prevResult: boolean, k: string) => {
    return (prevResult && has(obj, k));
  }, true);
}

export interface GsheetExpertInterface {
  id:                   string,
  slug:                 string,
  tier:                 string,
  name:                 string,
  expertiseDescription: string,
  location:             string,
  hashtags:             string[],
  companies:            string,
  profilePicture:       string,
  expertises:           string[],
  marketExpertise:      string[],
  languages:            string[],
  media:                string[],
  experiences:          GsheetExpertExperience[],
  education:            GsheetExpertEducation[],
};

interface ExpertMethods {
  getStringByKey(key: string): string,
};

export class GsheetExpert implements GsheetExpertInterface, ExpertMethods {
  id =                    '';
  slug =                  '';
  tier =                  '';
  name =                  '';
  expertiseDescription =  '';
  location =              '';
  hashtags =               [];
  companies =             '';
  profilePicture =        '';
  expertises =            [];
  marketExpertise =       [];
  languages =             [];
  media =                 [];
  experiences =           [];
  education =             [];
  // 
  constructor(obj: object = {}) {
    forEach(Object.keys(obj), k => {
      if(has(this, k)) {
        const v = get(this, k, Function);
        if (isArray(v) || isObject(v) || isString(v)) {
          switch(k) {
            case 'media':
            case 'hashtags':
            case 'languages':
            case 'expertises':
            case 'marketExpertise':
              // @ts-ignore
              this[k] = isArray(obj[k]) ? obj[k] : this.parseToArrayString(obj[k]);
              break;
            case 'experiences':
              // @ts-ignore
              this[k] = isArray(obj[k]) ? obj[k] : this.parseExperiences(obj[k]);
              break;
            case 'education':
              // @ts-ignore
              this[k] = isArray(obj[k]) ? obj[k] : this.parseEducation(obj[k]);
              break;
            default:
              this[k] = obj[k];
          }
          
        }
      }
    });
  }

  private retrieveValuesFromXML = (xmlDoc: Document, fields: string[] = []): object[] => {
    const rootNodeKeys = ['experiences', 'educations'];
    const nodeKeys = ['experience', 'education'];
    // @ts-ignore
    let xmlList: HTMLCollectionOf<Element> = [];

    if (xmlDoc.hasChildNodes()) {
      if (xmlDoc.childElementCount === 1 && rootNodeKeys.includes(xmlDoc.children[0].tagName)) {
        if (xmlDoc.children[0].tagName === rootNodeKeys[0]) {
          xmlList = xmlDoc.getElementsByTagName(nodeKeys[0]) || [];
        } else if (xmlDoc.children[0].tagName === rootNodeKeys[1]) {
          xmlList = xmlDoc.getElementsByTagName(nodeKeys[1]) || [];
        }
      } else if (xmlDoc.childElementCount >= 1 && nodeKeys.includes(xmlDoc.children[0].tagName)) {
        xmlList = xmlDoc.getElementsByTagName(xmlDoc.children[0].tagName) || [];
      }
      // 
      if (xmlList.length > 0) {
        let result: {[key: string]: string}[] = [];
        Array.from(xmlList).forEach((node: Element) => {
          result.push(
            fields.reduce((obj: {[key: string]: string}, k: string) => {
              obj[k] = get(node.getElementsByTagName(k), '[0].textContent', '');
              return obj;
            }, {})
          );
        });
        return result;
      }
    }

    return [];
  }

  //
  parseToArrayString = (str: string): string[] => {
    // console.log(str.split(/(( ,)|(, )|,| #|#|\r\n|\n)/gm));
    return reject(str.split(/(( ,)|(, )|,| #|#|\r\n|\n)/gm), (s) => (isEmpty(s) || (/^[#, \n]/).test(s)));
  };
  parseExperiences = (str: string): GsheetExpertExperience[] => {
    return this.retrieveValuesFromXML(
      (new DOMParser()).parseFromString(str,"text/xml"),
      getGsheetExpertExperienceKeys(),
    ) as GsheetExpertExperience[];
  }

  parseEducation = (str: string): GsheetExpertEducation[] => {
    return this.retrieveValuesFromXML(
      (new DOMParser()).parseFromString(str,"text/xml"),
      getGsheetExpertEducationKeys(),
    ) as GsheetExpertEducation[];
  }

  public update(key: string, value: string | string[] | GsheetExpertExperience[] | GsheetExpertEducation[]) {
    if (typeof this[key] === typeof value) {
      this[key] = value;
    } else {
      console.error('Diff type!');
    }
  }

  public getStringByKey(key: string): string {
    if (has(this, key)) {
      switch(key) {
        case 'hashtags':
        case 'languages':
        case 'expertises':
        case 'marketExpertise':
          return this[key].join(', ');
        default:
          return this[key];
      }
    }
    return '';
  }
}