import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import * as Views from './SectionWhoAreOurExperts.views';
import styles from './SectionWhoAreOurExperts.module.scss';

export default class SectionWhoAreOurExperts extends Component {
  renderGroup = (num: number) => {
    return (
      <Col key={`who-are-our-experts-group-${num}`} className={styles.useCase} xs="12" md="4">
        <Views.GroupTitle index={num} />
        <Views.GroupContent index={num} />
      </Col>
    );
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <Views.Title />
        <Row className={styles.useCaseWrapper}>
          { Array.from(Array(6).keys()).map(this.renderGroup) }
        </Row>
      </div>
    )
  }
}
