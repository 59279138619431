import React from 'react';
import { MESSAGE } from './VerifyUser.constants';
import styles from './VerifyUser.module.scss';

export const Processing = () => (
  <div className="d-block text-center">
    <p className={styles.verifying}>
      <i className="fas fa-spinner fa-pulse" /> Verifying...
    </p>
  </div>
);

const Message = ({ TITLE, DESC }) => (
  <div className="d-block text-center">
    <h2 className={styles.title}>{ TITLE }</h2>
    <p className={styles.description}>{ DESC }</p>
  </div>
);

export const Thanks = () => Message(MESSAGE.THANKS);
export const Invalid = () => Message(MESSAGE.INVALID);
export const Error = () => Message(MESSAGE.ERROR);