import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'page.contactXpersity.header.title',
    defaultMessage: 'Contact Xpersity',
  },
  description: {
    id: 'page.contactXpersity.header.description',
    defaultMessage: 'We will get back to you in the next 24 hours',
  }
})