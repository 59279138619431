import React from 'react';
import { get, has, isEmpty } from 'lodash';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { FacebookChatPlugin } from 'components/ChatPluginComponents';
import Header from './Header';
import Footer from './Footer';
import { Props, State } from './Layout.models';
import {
  INIT_PROPS,
  INIT_STATE,
  DEFAULT_BRAND_LOGO_MARGIN_TOP,
} from './Layout.constants';
import styles from './Layout.module.scss';

class Layout extends React.Component<Props, State> {
  static defaultProps: Props = INIT_PROPS;
  state: State = INIT_STATE;
  refScrollbar: React.RefObject<Scrollbars> = React.createRef();

  onScroll = () => {
    if (has(this.refScrollbar.current, 'getValues')) {
      const { isScrollDown } = this.state;
      const scrollInfo = this.refScrollbar.current?.getValues() || {};
      const scrollTop = get(scrollInfo, 'scrollTop', 0);
      const marginTop = scrollTop <= DEFAULT_BRAND_LOGO_MARGIN_TOP
                          ? DEFAULT_BRAND_LOGO_MARGIN_TOP - scrollTop
                          : 0;
      const opacity = scrollTop / DEFAULT_BRAND_LOGO_MARGIN_TOP;

      let newState: State = {
        brandLogoMarginTop: marginTop, 
        backdropOpacity:    opacity > 1 ? 1 : opacity,
        isScrollDown:       (scrollTop > 0 && !isScrollDown),
      };

      if (!isEmpty(newState)) {
        this.setState(newState);
      }
    }
  }

  render = () => {
    const {
      children,
      contentClass,
      containerClass,
      noHorizontalPadding,
    } = this.props;

    const { brandLogoMarginTop, backdropOpacity } = this.state;

    return (
      <Scrollbars
        autoHide
        className={styles.wrapper}
        ref={this.refScrollbar}
        onScroll={this.onScroll}
      >
        <Header
          backdropOpacity={backdropOpacity}
          brandLogoMarginTop={`${brandLogoMarginTop}px`}
        />
        <div className={classnames([styles.container, containerClass])}>
          <div className={
            classnames(
              [styles.content, contentClass],
              {[styles.contentHorizontalPadding]: !noHorizontalPadding}
            )}
          >
            { children }
          </div>
          <React.Suspense fallback={null}>
            <Footer />
          </React.Suspense>
        </div>
        <React.Suspense fallback={null}>
          <FacebookChatPlugin />
        </React.Suspense>
      </Scrollbars>
    )
  }
}

export default Layout;