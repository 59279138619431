import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { ROUTELIST } from 'routes';
import { Container } from 'react-bootstrap';
import { Locale } from 'types/locale';
import { HelmetTitle } from 'common/libs/helmet';
import { StateFromStore } from 'store/models';
import { actionGetListExperts } from 'store/gsheet';
import { Layout, ExpertFullDetail } from 'components';
import { BasicTextLoading } from 'components/LoadingComponents';
import { OwnActions, Props, State } from './ExpertDetail.models';
import { INIT_STATE } from './ExpertDetail.constants';
import styles from './ExpertDetail.module.scss';

class ExpertDetail extends React.Component<Props, State> {
  state: State = INIT_STATE;

  componentDidMount = () => {
    const id = get(this.props, 'match.params.id', '');
    if (id.length <= 0) {
      this.props.history.push(ROUTELIST.EXPERTS.URL);
    } else {
      const { experts, isGettingListExperts } = this.props;
      if (experts.length <= 0 && !isGettingListExperts) {
        this.props.doGetListExperts();
      } else {
        this.setUpdateExpertDetail();
      }
    }
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.experts.length !== this.props.experts.length) {
      if (this.props.experts.length <= 0) {
        this.props.history.push(ROUTELIST.EXPERTS.URL);
      } else {
        this.setUpdateExpertDetail();
      }
    }
  }

  setUpdateExpertDetail = () => {
    const id = get(this.props, 'match.params.id', '');
    const pos = this.props.experts.findIndex(e => e.slug === id || e.id === id);
    if (pos < 0) {
      this.props.history.push(ROUTELIST.EXPERTS.URL);
    } else {
      const { locale } = this.props.intl;
      const expertDetail = this.props.experts[pos].data(locale as Locale);
      const pageTitle = `The detail of ${expertDetail.name}`;
      this.setState({ pageTitle, expertDetail });  
    }
  }

  renderContent = () => {
    const { isGettingListExperts, experts } = this.props;
    if (isGettingListExperts) {
      return (<BasicTextLoading />);
    } else if (!isGettingListExperts && experts.length > 0) {
      return (<ExpertFullDetail expertDetail={this.state.expertDetail} />);
    }
  }

  render() {
    return (
      <React.Fragment>
        <HelmetTitle title={this.state.pageTitle} />
        <Layout>
          <Container className={styles.wrapper}>
            { this.renderContent() }
          </Container>
        </Layout>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: StateFromStore) => ({
  experts:                    state.gsheet.experts,
  expertsUpdatedAt:           state.gsheet.expertsUpdatedAt,
  isGettingListExperts:       state.gsheet.isGettingListExperts,
  getListExpertsStatus:       state.gsheet.getListExpertsStatus,
  getListExpertsErrorMessage: state.gsheet.getListExpertsErrorMessage,
});

const mapDispatchToProps = (dispatch): OwnActions => ({
  doGetListExperts: () => dispatch(actionGetListExperts()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ExpertDetail)));