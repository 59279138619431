import { Props, RequestFormData, State } from './RequestForm.models';
import { INIT_DATA as INIT_CONTACT_INFO_DATA } from './ContactInfo/ContactInfo.constants';
import { INIT_DATA as INIT_TIMESLOTS_DATA } from './TimeSlots/TimeSlots.constants';
import { INIT_DATA as INIT_ADVICENEEDS_DATA } from './AdviceNeeds/AdviceNeeds.constants';

export const INIT_PROPS: Props = Object.freeze({
} as Props);

export const INIT_REQUEST_FORM_DATA: RequestFormData = Object.freeze({
  ...INIT_CONTACT_INFO_DATA,
  ...INIT_TIMESLOTS_DATA,
  ...INIT_ADVICENEEDS_DATA,
  expertName: '',
} as RequestFormData);

export const INIT_STATE: State = Object.freeze({
  ...INIT_REQUEST_FORM_DATA,
  isShowMessage: false,
} as State);