import { has, omitBy, isEmpty } from 'lodash';
import { GsheetExpert } from './GsheetExpert';
import { Locale } from 'types/locale';

interface ExpertInterface {
  id: string,
  slug: string,
  [Locale.en]: GsheetExpert,
  [Locale.ko]: GsheetExpert,
}

export class Expert implements ExpertInterface {
  id = '';
  slug = '';
  [Locale.en] = new GsheetExpert({});
  [Locale.ko] = new GsheetExpert({});

  constructor(obj: object = {}) {
    for(const key in obj) {
      const gsheetExpertKey = key.replace(/(:ko|:en)$/gi, '');
      if (has(this, gsheetExpertKey)) {
        switch(gsheetExpertKey) {
          case Locale.en:
            this[Locale.en] = new GsheetExpert(obj[key]); break;
          case Locale.ko:
            this[Locale.ko] = new GsheetExpert(obj[key]); break;
          default:
            this[gsheetExpertKey] = obj[key];
        }
      } else {
        if (key.includes(':ko') || key.includes(':en')) {
          const lang = key.slice(-2);
          this[lang][gsheetExpertKey] = obj[key];
        } else {
          this.en[gsheetExpertKey] = obj[key];
          this.ko[gsheetExpertKey] = obj[key];
        }
      }
      // 
      this[Locale.en].id = this.id;
      this[Locale.ko].id = this.id;
      this[Locale.en].slug = this.slug;
      this[Locale.ko].slug = this.slug;
    }
  }

  public data(locale: Locale): GsheetExpert {
    return new GsheetExpert({
      ...this[Locale.en],
      ...omitBy(this[locale], isEmpty),
      id: this.id,
      slug: this.slug,
    });
  }
}