import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Container } from 'react-bootstrap';
import { Layout } from 'components';
import { BasicTextLoading } from 'components/LoadingComponents';
import { HelmetTitle } from 'common/libs/helmet';
import { StoreStatusTypes } from 'common/constants/store';
import { ROUTELIST } from 'routes';
import { Locale } from 'types/locale';
import { actionGetListExperts } from 'store/gsheet';
import { StateFromStore } from 'store/models';
import Header from './Header';
import Footer from './Footer';
import ListExperts from './ListExperts';
import { NoExpertsAvailable } from './Experts.views';
import { Props, OwnActions } from './Experts.models';
import { INIT_PROPS } from './Experts.constants';
import styles from './Experts.module.scss';

class Experts extends React.Component<Props> {
  static defaultProps: Props = INIT_PROPS;

  componentDidMount() {
    const { expertsUpdatedAt, experts } = this.props;
    if (expertsUpdatedAt.length < 0
    || experts.length <= 0
    || (expertsUpdatedAt.length > 0
        && moment(expertsUpdatedAt).isValid()
        && moment().isAfter(moment(expertsUpdatedAt).add(5,'minute'))
    )
    ) {
      this.props.doGetListExperts();
    }
  }

  renderContent = () => {
    const { isGettingListExperts, getListExpertsStatus, intl, experts } = this.props;
    if (isGettingListExperts) {
      return (<BasicTextLoading />);
    } else if (getListExpertsStatus === StoreStatusTypes.Error) {
      return (
        <React.Fragment>
          <NoExpertsAvailable />
          <Footer />
        </React.Fragment>
      );
    } else if (!isGettingListExperts && experts.length > 0) {
      const { locale } = intl;
      const listExperts = experts.map(e => e.data(locale as Locale));
      return (
        <React.Fragment>
          <ListExperts experts={listExperts} />
          <Footer />
        </React.Fragment>
      );
    }
    return '';
  }

  render() {
    return (
      <React.Fragment>
        <HelmetTitle title={ROUTELIST.EXPERTS.NAME} />
        <Layout>
          <Container className={styles.wrapper}>
            <Header />
            { this.renderContent() }
          </Container>
        </Layout>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: StateFromStore) => ({
  experts:                    state.gsheet.experts,
  expertsUpdatedAt:           state.gsheet.expertsUpdatedAt,
  isGettingListExperts:       state.gsheet.isGettingListExperts,
  getListExpertsStatus:       state.gsheet.getListExpertsStatus,
  getListExpertsErrorMessage: state.gsheet.getListExpertsErrorMessage,
});

const mapDispatchToProps = (dispatch): OwnActions => ({
  doGetListExperts: () => dispatch(actionGetListExperts()),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Experts));