import moment from 'moment';
// import momentTimezone from 'moment-timezone';
import { removeTime } from 'common/libs/moment';
import { TimeSlotsData } from '../RequestForm.models';
import { Props, State } from './TimeSlots.models';

export const ON_CHANGED_TIMEOUT = 1000;
export const DEFAULT_DATE = removeTime(moment().add(1, 'day')).format();

export const INIT_PROPS: Props = Object.freeze({
  onChanged: () => {},
} as Props);

export const INIT_DATA: TimeSlotsData = Object.freeze({
  // timezone:       momentTimezone.tz.guess(true),
  // preferredDates: [DEFAULT_DATE],
  timezone:       '',
  preferredDates: [],
} as TimeSlotsData);

export const INIT_STATE: State = Object.freeze({
  ...INIT_DATA,
  showDatePicker: false,
  editAt:         -1,
} as State);

export const DATETIME_FORMAT = Object.freeze({
  FULL: 'ddd, MMM Do YY hh:mm a',
  DATE: 'ddd, MMM Do YY',
  TIME: 'hh:mm a',
});