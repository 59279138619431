import React from 'react';
import { useIntl } from 'react-intl';
import {
  globalConnection as imgGlobalConnection,
} from 'common/assets';
import { getBlankValues, AutoFormatMessage } from 'common/libs/intl';
import messages from './SectionHero.messages';
import styles from './SectionHero.module.scss';

export const Title = () => (<h2><AutoFormatMessage message={messages.title} /></h2>);

export const Description = () => (<p><AutoFormatMessage message={messages.description} /></p>);

export const Image = () => {
  const intl = useIntl();
  return (
    <img
      className={styles.featureImg}
      src={imgGlobalConnection}
      alt={ intl.formatMessage(messages.title, getBlankValues()) as string }
    />
  );
}