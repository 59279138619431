import React from 'react';
import { ButtonContactXpersity } from 'components';
import { AutoFormatMessage } from 'common/libs/intl';
import messages from './Footer.messages';
import styles from './Footer.module.scss';

export default class Footer extends React.PureComponent {
  render() {
    return (
      <div className={styles.wrapper}>
        <h5 className={styles.title}>
          <AutoFormatMessage message={messages.title} />
        </h5>
        <ButtonContactXpersity size="lg" />
      </div>
    );
  }
}


