import { MediumArticle } from 'models/Article';
import { StoreStatusTypes } from 'common/constants/store';

export enum ActionTypes {
  GetMediumArticles = 'GET_MEDIUM_ARTICLES',
  GetArticleDetail = 'GET_ARTICLE_DETAIL',
};

// 
export interface StoreGetMediumArticles {
  isGettingMediumArticles:        boolean,
  mediumArticles:                 MediumArticle[],
  getMediumArticlesStatus:        StoreStatusTypes,
  getMediumArticlesErrorMessage:  '',
}

export interface StoreGetArticleDetail {
  isGettingArticleDetail:       boolean,
  article:                      MediumArticle,
  getArticleDetailStatus:       StoreStatusTypes,
  getArticleDetailErrorMessage: '',
}

export interface State extends
StoreGetMediumArticles,
StoreGetArticleDetail
{}