import React from 'react';
import { AutoFormatMessage } from 'common/libs/intl';
import { APP_MESSAGES } from 'common/constants/languages';

const BasicText = () => (
  <div className="d-block">
    <p className="text-center text-secondary font-weight-light" style={{fontSize: '1.5rem'}}>
    <i className="fas fa-spinner fa-pulse" /> <AutoFormatMessage message={APP_MESSAGES.loading} />...
    </p>
  </div>
);

export default BasicText;