import { StoreStatusTypes } from 'common/constants/store';

export enum ActionTypes {
  ContactXpersity = 'CONTACT_XPERSITY',
};

// 
export interface StoreContactXpersity {
  isContactingXpersity:         boolean,
  contactXpersityStatus:        StoreStatusTypes,
  contactXpersityErrorMessage:  '',
}

export interface State extends
  StoreContactXpersity
{}