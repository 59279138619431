import React from 'react';
import { AutoFormatMessage } from 'common/libs/intl';
import { APP_MESSAGES } from 'common/constants/languages';
import messages from './SectionDecisions.messages';
import styles from './SectionDecisions.module.scss';


export const Title = () => (
  <h2 className={styles.contentTitle}>
    <AutoFormatMessage message={messages.title} />
  </h2>
);

export const Description = () => (
  <p><AutoFormatMessage message={messages.description} /></p>
);

export const TextFindExpert = () => (
  <AutoFormatMessage message={APP_MESSAGES.findYourExpert} />
);