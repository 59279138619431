import React from 'react';
import { pick, isEqual } from 'lodash';
import { Form, Row, Col } from 'react-bootstrap';
import { ContactInfoData } from '../RequestForm.models';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import messages from './ContactInfo.messages';
import { Props, State } from './ContactInfo.models';
import { INIT_PROPS, INIT_STATE, INIT_DATA, ON_CHANGED_TIMEOUT } from './ContactInfo.constants';

class ContactInfo extends React.Component<Props & WrappedComponentProps, State> {
  static defaultProps: Props = INIT_PROPS;
  state: State = INIT_STATE;
  onChangedTimeout: NodeJS.Timeout | null = null;

  // COMPONENT EVENTS
  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const newData = pick(this.state, Object.keys(INIT_DATA)) as ContactInfoData;
    const oldData = pick(prevState, Object.keys(INIT_DATA)) as ContactInfoData;
    if (!isEqual(newData, oldData)) {
      this.handleUpdateOnChangeTimeout(newData);
    }
  }

  // HANDLES
  handleOnChangeData = (key: 'firstName' | 'lastName' | 'email' | 'preferredLanguage', newValue: string) => {
    this.setState({ [key]: newValue } as Pick<State, keyof State>);
  }

  handleUpdateOnChangeTimeout = (newData: ContactInfoData) => {
    if (this.props.onChanged) {
      if (this.onChangedTimeout !== null) {
        clearTimeout(this.onChangedTimeout);
      }
      this.onChangedTimeout = setTimeout(
        (() => this.props.onChanged(newData)).bind(this), // eslint-disable-line no-extra-bind
        ON_CHANGED_TIMEOUT
      );
    }
  }

  render() {
    const { formatMessage } = this.props.intl;
    return (
      <Row>
        <Col xs="12" md="4" lg="3">
          <Form.Group controlId="firstName">
            <Form.Label>
              { formatMessage(messages.firstName) }
            </Form.Label>
            <Form.Control
              required
              as="input"
              type="text"
              maxLength={150}
              placeholder={ formatMessage(messages.firstName) as string}
              onChange={(e) => this.handleOnChangeData("firstName", e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xs="12" md="4" lg="3">
          <Form.Group controlId="lastName">
            <Form.Label>
              { formatMessage(messages.lastName) }
            </Form.Label>
            <Form.Control
              required
              as="input"
              type="text"
              maxLength={150}
              placeholder={ formatMessage(messages.lastName) as string }
              onChange={(e) => this.handleOnChangeData("lastName", e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xs="12" md="4" lg="6">
          <Form.Group controlId="email">
            <Form.Label>
              { formatMessage(messages.email) }
            </Form.Label>
            <Form.Control
              required
              as="input"
              type="email"
              maxLength={255}
              placeholder={ formatMessage(messages.email) as string }
              onChange={(e) => this.handleOnChangeData("email", e.target.value)}
            />
          </Form.Group>
        </Col>
        {/* <Col xs="12" md="6">
          <Form.Group controlId="language">
            <Form.Label>
              { formatMessage(messages.preferredLanguage) }
            </Form.Label>
            <Form.Control
              required
              as="input"
              type="text"
              maxLength={255}
              placeholder={ formatMessage(messages.preferredLanguage) }
              onChange={(e) => this.handleOnChangeData("preferredLanguage", e.target.value)}
            />
          </Form.Group>
        </Col> */}
      </Row>
    )
  }
}

export default injectIntl(ContactInfo);