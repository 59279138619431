import React from 'react';
import { Container } from 'react-bootstrap';
import { HelmetTitle } from 'common/libs/helmet';
import Layout from 'components/Layout';
import styles from './NotFound.module.scss';

export default class NotFound extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <HelmetTitle title="404" />
        <Layout>
          <Container className={styles.wrapper}>
            <p className={styles.symbol}>404</p>
            <h1 className={styles.title}>Page Not Found</h1>
            <p className={styles.description}>The page you are looking for does not exist.</p>
          </Container>
        </Layout>
      </React.Fragment>
    )
  }
}
