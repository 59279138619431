import React from 'react';
import { AutoFormatMessage } from 'common/libs/intl';
import MESSAGES from './Insights.messages';

export const NoArticlesAvailable = () => (
  <div className="d-block mb-5">
    <p className="text-center text-secondary font-weight-light" style={{fontSize: '1.5rem'}}>
    <AutoFormatMessage message={MESSAGES.noArticlesAvailable} />
    </p>
  </div>
);