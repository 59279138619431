import buildUrl from 'build-url';
import axios from 'common/libs/axios';
import { API_USER } from 'common/constants/api';

export const verifyUser = (code: string) => {
  const url = buildUrl(API_USER, { path: `${code}/verifyUser` });
  return axios.post(url, null);
};

export const verifyExpert = (code: string) => {
  const url = buildUrl(API_USER, { path: `${code}/verifyExpert` });
  return axios.post(url, null);
};
