import React from 'react';
import classnames from 'classnames';
import { AutoFormatMessage } from 'common/libs/intl';
import messages from './Header.messages';
import styles from './Header.module.scss';

export default class Header extends React.Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <h4 className={classnames(['mb-0 text-center text-md-left', styles.title])}>
          <AutoFormatMessage message={messages.title} />
        </h4>
      </div>
    )
  }
}
