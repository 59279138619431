import { has } from 'lodash';
import { createLogger } from 'redux-logger';
import { ActionStore } from 'models/Store';

const logger = createLogger({
  collapsed: true,
  duration: true,
  titleFormatter: (action: ActionStore, time: String, took: Number) => {
    const name = has(action, 'state') ? `${action.type} | ${action.state}` : action.type;
    const displayName = `\x1b[30m${name}\x1b[0m`;
    const displayTime = `\x1b[30m${time} \x1b[90m(in ${took.toFixed(2)} ms)\x1b[0m`;
    return `\x1b[90m action\x1b[0m ${displayName} \x1b[90m@\x1b[0m ${displayTime}\x1b[0m`;
  }
});

export default logger;
