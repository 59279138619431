import { ROUTELIST } from 'routes';
import { MessageDescriptor } from 'react-intl';
import { Locale } from 'types/locale';
import { APP_MESSAGES } from 'common/constants/languages';
import { Props } from './Navigation.models';

export const NAVIGATION_KEY = 'top-navigation';
export const INIT_PROPS: Props = Object.freeze({
  className: '',
});

export const NAV_LINKS: {
  message:          MessageDescriptor,
  key:              string,
  url:              string,
  is_external:      boolean,
  locale_restrict:  string[],
}[] = [
  {
    message: APP_MESSAGES.insights,
    key: ROUTELIST.INSIGHTS_MEDIUM.KEY,
    url: ROUTELIST.INSIGHTS_MEDIUM.URL,
    is_external: false,
    locale_restrict: [Locale.ko],
  },
  {
    message: APP_MESSAGES.insights,
    key: ROUTELIST.INSIGHTS_NAVER.KEY,
    url: ROUTELIST.INSIGHTS_NAVER.URL,
    is_external: true,
    locale_restrict: [Locale.en],
  },
  {
    message: APP_MESSAGES.findYourExpert,
    key: ROUTELIST.EXPERTS.KEY,
    url: ROUTELIST.EXPERTS.URL,
    locale_restrict: [],
    is_external: false,
  },
  {
    message: APP_MESSAGES.becomeAnExpert,
    key: ROUTELIST.EXPERT_REGISTRATION.KEY,
    url: ROUTELIST.EXPERT_REGISTRATION.URL,
    locale_restrict: [],
    is_external: false,
  },
];