const IS_PRODUCTION = process.env.NODE_ENV === 'production' || false;
const APP_NAME = process.env.REACT_APP_NAME || 'Xpersity';
const API_URL = process.env.REACT_APP_API || '';
const HTTP_TIMEOUT = +(process.env.REACT_APP_HTTP_TIMEOUT || 0);
const AMOUNT_PER_PAGE = parseInt(`${process.env.REACT_APP_AMOUNT_PER_PAGE || 12}`, 10);
const AMOUNT_PAGE_ON_PAGINATION = parseInt(`${process.env.REACT_APP_AMOUNT_PAGE_ON_PAGINATION || 3}`, 10);
const MAX_PREFERRED_SCHEDULE = parseInt(`${process.env.REACT_APP_MAX_PREFERRED_SCHEDULE || 4}`, 10);
const MAX_SCHEDULE_GAP = parseInt(`${process.env.REACT_APP_MAX_SCHEDULE_GAP || 30}`, 10);
const TIME_SLOT_GAP = parseInt(`${process.env.REACT_APP_TIME_SLOT_GAP || 15}`, 10);

const TRACKING_GTM = process.env.REACT_APP_GTM || '';
// const TRACKING_GA_ID = process.env.REACT_APP_GA || '';
// const TRACKING_FB_PIXEL_ID = process.env.REACT_APP_FB_PIXEL || '';

const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE || 'en';

const NOTI_AUTO_HIDE_TIME = 5000;
const NOTI_MAX_DISPLAY = 5;

export {
  IS_PRODUCTION,
  APP_NAME,
  API_URL,
  MAX_PREFERRED_SCHEDULE,
  MAX_SCHEDULE_GAP,
  TIME_SLOT_GAP,
  AMOUNT_PAGE_ON_PAGINATION,
  AMOUNT_PER_PAGE,
  HTTP_TIMEOUT,
  TRACKING_GTM,
  // TRACKING_GA_ID,
  // TRACKING_FB_PIXEL_ID,
  // DATETIME_FORMAT,
  DEFAULT_LOCALE,
  NOTI_AUTO_HIDE_TIME,
  NOTI_MAX_DISPLAY,
  // LIST_EXPERTS_GSHEET_LOCALE,
};
