import { Props } from './MoreDetail.models';
import { GsheetExpert } from 'models/GsheetExpert';

export const INIT_PROPS: Props = Object.freeze({
  className:    '',
  expertDetail: new GsheetExpert({}),
});

export const DEFAULT_QUERY_KEYS: string[] = ['media', 'expertises', 'marketExpertise', 'experiences', 'education', 'languages'];
export const TITLE_MAP: {[key: string]: string} = {
  media:            'Media',      
  expertises:       'Domain/Subject Expertise',
  marketExpertise:  'Market Expertise',
  experiences:      'Experience',
  education:        'Education',
  languages:        'Language',
}