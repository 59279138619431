/* eslint "jsx-a11y/anchor-is-valid": "off" */
import React from 'react';
import { renderToString } from 'react-dom/server';

const OldHTML = () => (
  <React.Fragment>
    <link rel="stylesheet" href="device-mockups/device-mockups.min.css" />
    <link rel="stylesheet" href="//code.jquery.com/ui/1.12.1/themes/base/jquery-ui.css" />
    <link rel="stylesheet" href="css/style.css" />
    <link rel="stylesheet" href="css/bs-stepper.min.css" />
    <link rel="stylesheet" href="css/new-age.min.css" />
    <link rel="stylesheet" href="css/responsive.css" />
    <section className="text-center">
      <div className="container">
        <div id="stepperLinkedin" className="bs-stepper linear">
          <div className="bs-stepper-header" role="tablist">
            <div id="step-1" className="step active" data-target="#content-step-1">
              <button type="button" className="step-trigger" role="tab" id="stepper1trigger1" aria-controls="test-l-1" aria-selected="true" style={{fontSize: '2rem'}}>
                <span className="bs-stepper-circle">1</span>
                <span className="bs-stepper-label">Confirm Basic Information</span>
              </button>
            </div>
            <div className="bs-stepper-line"></div>
            <div id="step-2" className="step" data-target="#content-step-2">
              <button type="button" className="step-trigger" role="tab" id="stepper1trigger2" aria-controls="test-l-2" aria-selected="false" style={{fontSize: '2rem'}} disabled>
                <span className="bs-stepper-circle">2</span>
                <span className="bs-stepper-label">About You</span>
              </button>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col-md-12 m-auto">
              <form className="signup-form tab-content">
                <div className="tab-pane pt-5 active show" id="content-step-1">
                  <div className="form-group">
                    <label>Profile Picture</label>
                    <div
                      className="chooseImage d-flex align-items-center"
                      style={{marginLeft: 0, padding: 0, overflow: 'hidden'}}
                      dangerouslySetInnerHTML={{
                        __html: `
                        <img id="linkedinProfileImage" onload="updateProfilePicture()" style="width: 100%; height: 100%; max-width: 100%; max-height: 100%" crossorigin="anonymous" />
                        <img id="linkedinProfileImageInvisible" class="d-none" crossorigin="anonymous" />
                        `
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="firstName">First Name <span>*</span></label>
                    <input type="text" className="form-control" id="firstName" placeholder="First Name" />
                    <small id="messageFirstName" className="form-text text-muted message"></small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName">Last Name <span>*</span></label>
                    <input type="text" className="form-control" id="lastName" placeholder="Last Name" />
                    <small id="messageLastName" className="form-text text-muted message"></small>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email <span>*</span></label>
                    <input type="email" className="form-control" id="email" aria-describedby="emailHelp" placeholder="Email" />
                    <small id="msgHintEmail" className="form-text text-muted message">
                      * Please use your most updated email address
                    </small>
                    <small id="messageEmail" className="form-text text-muted message"></small>
                  </div>
                  <button type="button" id="btn-next" className="btn-next thm-button">Next &gt;</button>
                </div> 
                <div className="tab-pane pt-5" id="content-step-2">
                  <div className="form-group">
                      <label htmlFor="aboutYouself">About Yourself <span>*</span></label>
                      <textarea className="form-control" id="aboutYouself" rows={6} placeholder="Describe your professional or academic experience and domain knowledge that demonstrate your expertise and ability to advise/mentor. (up to 1,500 characters)"></textarea>
                      <small id="messageAbout" className="form-text text-muted message"></small>
                      <div className="text-left about-description">
                        <small id="example" className="form-text text-muted">Example: 15yr+ experience in automotive industry with specialization in designing driver-assistance systems. Currently leading development of autonomous vehicle in XYZ. Also I was a co-founder of ABC, which later was acquired by GE. In ABC, I led development of new in-automotive entertainment systems and its market expansion to Latin America as the COO in ABC. I’m also an expert in applying deep learning to auto-driving system and knowledgeable about its latest trend and researches.</small>
                      </div>
                  </div>
                  <div
                    className="form-group position-relative"
                    dangerouslySetInnerHTML={{
                      __html: `
                        <label for="knowledge">Areas of Expertise / Knowledge for Sharing <span>*</span></label>
                        <input type="text" class="form-control" onchange="getDataSelectedInputChange()" onkeyup="checkInputForKnowledge()" id="knowledge" placeholder="Please give a list of your areas of expertise and knowledge (e.g., sectors, functions, geographic markets)" />
                        <div id="knowledgeResults" class="d-flex flex-wrap"></div>
                        <small id="messageKnowledge" class="form-text text-muted message"></small>
                      `
                    }}
                  />
                  <div
                    className="form-group position-relative"
                    dangerouslySetInnerHTML={{
                      __html: `
                        <label for="location">Market(s) coverage of your expertise (Country) <span>*</span></label>
                        <input type="text" class="form-control" onchange="getDataSelectedLocationsChange()" onkeyup="checkInputForLocations()"  id="location" placeholder="Ex: Singapore" minlength="3" maxlength="255" required />
                        <div id="locationResults" class="d-flex flex-wrap"></div>
                        <small id="messageLocation" class="form-text text-muted message"></small>
                      `
                    }}
                  />
                  <div
                    className="form-group position-relative"
                    dangerouslySetInnerHTML={{
                      __html: `
                        <label for="languages">Languages for Giving Advice <span>*</span></label>
                        <input type="text" class="form-control" onchange="getDataSelectedLanguagesChange()" onkeyup="checkInputForLanguages()" id="languages" placeholder="Ex: English, Chinese" />
                        <div id="languageResults" class="d-flex flex-wrap"></div>
                        <small id="messageLanguages" class="form-text text-muted message"></small>
                      `
                    }}
                  />
                  <div
                    className="form-group position-relative"
                    dangerouslySetInnerHTML={{
                      __html: `
                        <label for="linkedinURL">Confirm LinkedIn Profile URL <span>*</span></label>
                        <input type="url" class="form-control" onchange="checkInputForLinkedinURL()" onkeyup="checkInputForLinkedinURL()" id="linkedinURL" placeholder="e.g. https://www.linkedin.com/in/your-profile/" required autoComplete="off" />
                        <small id="messageLinkedinURL" class="form-text text-muted message"></small>
                      `
                    }}
                  />
                  <div
                    className="form-group position-relative"
                    dangerouslySetInnerHTML={{
                      __html: `
                        <label for="referralEmail">Referral email <span>(Optional)</span></label>
                        <input type="email" class="form-control" id="referralEmail" placeholder="Please add a referral email" autoComplete="off" />
                        <small id="messageLinkedinURL" class="form-text text-muted message"></small>
                      `
                    }}
                  />
                  <div className="form-group">
                    <label htmlFor="displayName">Display Name <span className="optional">(Optional)</span></label>
                    <select className="form-control" id="displayName"></select>
                  </div>
                  <div className="form-group d-flex justify-content-between">
                    <button type="button" id="btn-back" className="btn-next thm-button">&lt; Back</button>
                    <button type="button" id="btn-submit" className="btn-next thm-button">Submit &gt;</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          
          <div className="modal custom-modal" id="successModal" tabIndex={-1} role="dialog" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog custom-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body success">
                    You application has been received and will be processed within 48 hours. An email will be sent to you after the review. Thank you.
                </div>
                <div className="modal-footer">
                  <button type="button" id="close-modal-success" className="btn-next thm-button" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal custom-modal" id="errorModal" tabIndex={-1} role="dialog" aria-labelledby="errorModalLabel" aria-hidden="true">
            <div className="modal-dialog custom-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body error">
                  Submit is error Please check all field!
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn-next thm-button" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <script src="vendor/jquery/jquery.min.js" />
    <script src="vendor/bootstrap/js/bootstrap.bundle.min.js" />
    <script src="js/jquery.js" />
    <script src="https://code.jquery.com/ui/1.12.1/jquery-ui.js" />
    <script src="js/bootstrap.min.js" />
    <script src="js/utils/constant.js?ver=0.3" />
    <script src="js/utils/helper.js?ver=0.2" />
    <script src="js/utils/service.js?ver=0.2" />
    <script src="js/utils/autocompleteExpertise.js?ver=0.2" />
    <script src="js/utils/autocompleteLang.js?ver=0.2" />
    <script src="js/utils/autocompleteLocation.js?ver=0.3" />
    <script src="js/new-age.js?ver=0.3" />
    <script src="js/register.js?ver=0.3" />
    <script src="js/registerWithLinkedin.js?ver=0.2" />
    <script src="js/bs-stepper.min.js" />
  </React.Fragment>
);

export default () => (
  <React.Fragment>
    <iframe
      id="register_by_linkedin_frame"
      title="register_by_linkedin_frame"
      allow="true"
      width="100%"
      style={{ border: 'none' }}
      srcDoc={renderToString(OldHTML())}
    />
  </React.Fragment>
)