import React from 'react';
import { getFormattedTime } from 'common/libs/moment';
import { Props } from './ArticleUnit.models';
import { INIT_PROPS } from './ArticleUnit.constants';
import styles from './ArticleUnit.module.scss';

export default class ArticleUnit extends React.PureComponent<Props> {
  static defaultProps = INIT_PROPS;

  handleOnClick = () => {
    const { article, onClick } = this.props;
    if (onClick !== null && typeof onClick === "function") {
      onClick(article);
    } else {
      window.open(article.friendlyUrl);
    }
  }

  render() {
    return (
      <article className={styles.wrapper} onClick={this.handleOnClick}>
        <div className={styles.imageWrapper}>
          <img
            className={styles.image}
            src={this.props.article.coverImage}
            alt={this.props.article.title}
          />
        </div>
        <div className={styles.date}>
          { getFormattedTime(this.props.article.publishedAt) }
        </div>
        <div className={styles.title}>
          { this.props.article.title }
        </div>
      </article>
    )
  }
}
