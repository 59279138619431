import {
  State,
  StoreGetMediumArticles,
  StoreGetArticleDetail,
} from './article.models';
import { StoreStatusTypes } from 'common/constants/store';

export const MODULE_NAME = 'article';

export const INIT_MEDIUM_ARTICLES: StoreGetMediumArticles = Object.freeze({
  mediumArticles:                 [],
  isGettingMediumArticles:        false,
  getMediumArticlesStatus:        StoreStatusTypes.Undefined,
  getMediumArticlesErrorMessage:  '',
} as StoreGetMediumArticles);

export const INIT_ARTICLE_DETAIL: StoreGetArticleDetail = Object.freeze({
  article:                      {},
  isGettingArticleDetail:       false,
  getArticleDetailStatus:       StoreStatusTypes.Undefined,
  getArticleDetailErrorMessage: '',
} as StoreGetArticleDetail);

export const INIT_STATE: State = Object.freeze({
  ...INIT_MEDIUM_ARTICLES,
  ...INIT_ARTICLE_DETAIL,
});