import moment from 'moment';
import { MAX_SCHEDULE_GAP } from 'common/constants/env';
import { Props, State } from './ModalDatePicker.models';

export const INIT_PROPS: Props = Object.freeze({
  selected:   '',
  autoClose:  false,
  minDate:    moment().add(1, 'day').format(),
  maxDate:    moment().add(MAX_SCHEDULE_GAP, 'day').format(),
  // 
  onSelected: () => {},
} as Props);

export const INIT_STATE: State = Object.freeze({
  pickerSelected: '',
} as State);