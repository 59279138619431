import { isEmpty, get } from 'lodash';
import { Expert } from 'models/Expert';
import { TranslationMessageGroup } from 'store/language/language.models';

export const buildListExpertFromGsheetData = (data: object[] = []): Expert[] => {
  if (!isEmpty(data)) {
    return data.reduce((prev: Expert[], obj: Object, index: number) => {
      prev.push(new Expert({ ...obj, id: `expert-${index}` }));
      return prev;
    }, []);
  }
  return [];
};

export const buildTranslationFromGsheetData = (data: object[] = []): TranslationMessageGroup => {
  if (!isEmpty(data)) {
    return data.reduce((prev: TranslationMessageGroup, obj: object, index: number) => {
      const key = get(obj, 'key', '');
      if (!isEmpty(key)) {
        prev.en[key] = get(obj, 'en', '');
        prev.ko[key] = get(obj, 'ko', '');
      }
      return prev;
    }, { en: {}, ko: {}});
  }
  return { en: {}, ko: {} };
}

export const transformPersistData = (listObjects: object[] = []): Expert[] => {
  if (!isEmpty(listObjects) && listObjects.length > 0) {
    return listObjects.reduce((prev: Expert[], obj: object, index: number) => {
      prev.push(new Expert(obj));
      return prev;
    }, []);
  }
  return [];
}

