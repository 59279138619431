import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'page.home.sectionDecisions.title',
    defaultMessage: 'Our insights demystify emergent opportunities',
  },
  description: {
    id: 'page.home.sectionDecisions.description',
    defaultMessage: 'Formulate your investment, partnership and growth strategies through our unique insights',
  },
});
