import React from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { HelmetTitle } from 'common/libs/helmet';
import { StoreStatusTypes } from 'common/constants/store';
import { ROUTELIST } from 'routes';
import { Layout } from 'components';
import { BasicTextLoading } from 'components/LoadingComponents';
import { StateFromStore } from 'store/models';
import { actionGetMediumArticles } from 'store/article';
import Header from './Header';
// import Footer from './Footer';
import ListArticles from './ListArticles';
import { NoArticlesAvailable } from './Insights.views';
import { Props, OwnActions } from './Insights.models';
import { INIT_PROPS } from './Insights.constants';
import styles from './Insights.module.scss';

class Insights extends React.Component<Props> {
  static defaultProps: Props = INIT_PROPS;

  componentDidMount() {
    const { isGettingMediumArticles } = this.props;
    if (!isGettingMediumArticles) {
      this.props.doGetListArticles && this.props.doGetListArticles();
    }
  }

  renderContent = () => {
    const { isGettingMediumArticles, getMediumArticlesStatus, mediumArticles } = this.props;
    if (isGettingMediumArticles) {
      return (<BasicTextLoading />);
    } else if (getMediumArticlesStatus === StoreStatusTypes.Error) {
      return (<NoArticlesAvailable />);
    } else if (!isGettingMediumArticles && mediumArticles.length > 0) {
      return (<ListArticles articles={mediumArticles} />);
    }
    return null;
  }

  render() {
    return (
      <React.Fragment>
        <HelmetTitle title={ROUTELIST.INSIGHTS_MEDIUM.NAME} />
        <Layout>
          <Container className={styles.wrapper}>
            <Header />
            { this.renderContent() }
            {/* <Footer /> */}
          </Container>
        </Layout>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: StateFromStore) => ({
  mediumArticles: state.article.mediumArticles,
  isGettingMediumArticles: state.article.isGettingMediumArticles,
  getMediumArticlesStatus: state.article.getMediumArticlesStatus,
  getMediumArticlesErrorMessage: state.article.getMediumArticlesErrorMessage,
});

const mapDispatchToProps = (dispatch): OwnActions => ({
  doGetListArticles: () => dispatch(actionGetMediumArticles()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Insights);