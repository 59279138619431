import { StoreStatusTypes } from 'common/constants/store';
import { Locale } from 'types/locale';

export enum ActionTypes {
  GetTranslation = 'GET_TRANSLATION',
  UpdateLocale  = 'UPDATE_LOCALE',
};

export interface TranslationMessageGroup {
  en: {[key: string]: string},
  ko: {[key: string]: string},
}
// 
export interface StoreTranslation {
  isGettingTranslation:       boolean,
  getTranslationStatus:       StoreStatusTypes,
  translationMessageGroup:    TranslationMessageGroup,
  updatedAt:                  string,
  getTranslationErrorMessage: '',
}

export interface StoreLocale {
  locale: Locale,
}

export interface State extends
  StoreTranslation,
  StoreLocale
{}