import { getReducerData } from 'common/libs/reducer';
import { ActionStore, ReducerKeyMapping } from 'models/Store';
import { ActionTypes, State } from './user.models';
import { INIT_STATE } from './user.constants';

export default (state: State = INIT_STATE, action: ActionStore) => {
  switch (action.type) {
    case ActionTypes.VerifyUser:
    case ActionTypes.VerifyExpert:
      return getReducerData(state, action, {
        loading:      'isVerifingAccount',
        statusType:   'verifyAccountStatus',
        errorMessage: 'verifyAccountErrorMessage',
      } as ReducerKeyMapping);

    default:
      return state;
  }
};
