import { ReactNode } from 'react';
import { StoreLocale, StoreTranslation } from 'store/language/language.models';

export interface OwnActions {
  doGetTranslation: Function,
  doUpdateLocale:   Function,
};

export enum Language {
  'en' = 'english',
  'ko' = 'korean',
};

export interface Props extends OwnActions, StoreLocale, StoreTranslation {
  children: ReactNode | null,
};