import React from 'react';
import { withRouter } from 'react-router-dom';
import { ROUTELIST } from 'routes';
import { ExpertShortDetail } from 'components';
import { GsheetExpert } from 'models/GsheetExpert';
import { Props } from './ListExperts.models';
import { INIT_PROPS } from './ListExperts.constants';

class ListExperts extends React.Component<Props> {
  static defaultProps: Props = INIT_PROPS;
  handleOnClickToViewExpertDetail = (expert: GsheetExpert) => {
    const { history } = this.props;
    const { id, slug } = expert;
    const { URL: url, PARAMS: params } = ROUTELIST.EXPERT_DETAIL;
    const urlTransformed: string = params.reduce((r: string, p: string) => {
      if(p === 'id') { return r.replace(/(:id)/g, slug || id); }
      return r;
    }, url);
    // 
    history.push(urlTransformed);
  }

  renderExpertInfo = (expert: GsheetExpert) => {
    return (
      <ExpertShortDetail
        // hideReadMore
        expertDetail={expert}
        onClick={() => this.handleOnClickToViewExpertDetail(expert)}
        key={`expert-short-detail-${expert.id}`}
      />
    );
  }

  render() {
    return (
      <div>
        { this.props.experts.map(this.renderExpertInfo) }
      </div>
    );
  }
}

export default withRouter(ListExperts);
