// import {Locale} from 'types/locale';
// const DATETIME_FORMAT = Object.freeze({
//   [Locale.en]: {
//     FULL: 'ddd, MMM Do YY hh:mm a',
//     DATE: 'ddd, MMM Do YY',
//     TIME: 'hh:mm a',
//   },
//   [Locale.ko]: {
//     FULL: 'LLL',
//     DATE: 'll',
//     TIME: 'LT',
//   },
// });
export const DATETIME_FORMAT = Object.freeze({
  FULL: 'LLL',
  DATE: 'll',
  TIME: 'LT',
});