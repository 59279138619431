import { ActionStore, ReducerKeyMapping } from 'models/Store';
import { ActionStates, StoreStatusTypes } from 'common/constants/store';

export const getReducerData = (state: object, action: ActionStore, keys: ReducerKeyMapping) => {
  switch (action.state) {
    case ActionStates.Start:
      return {
        ...state,
        [keys.loading]: true,
        [keys.statusType]: StoreStatusTypes.Undefined,
      };
    case ActionStates.Success:
      return {
        ...state,
        ...action.payload,
        [keys.loading]: false,
        [keys.statusType]: StoreStatusTypes.Success,
      };
    case ActionStates.Failure:
      return {
        ...state,
        [keys.loading]: false,
        [keys.statusType]: StoreStatusTypes.Error,
        [keys.errorMessage]: action.payload,
      };
    case ActionStates.Reset:
      return {
        ...state,
      };
    default:
      return state;
  }
};