import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { ArticleUnit } from 'components';
// import { ROUTELIST } from 'routes';
import { MediumArticle } from 'models/Article';
import { actionViewArticle } from 'common/libs/article';
import * as Views from './SectionInsights.views';
import { Props } from './SectionInsights.models';
import { INIT_PROPS } from './SectionInsights.constants';
import styles from './SectionInsights.module.scss';

class SectionInsights extends Component<Props> {
  static defaultProps: Props = INIT_PROPS;

  // handleOnClickToViewArticle = (article: MediumArticle) => {
  //   const { history } = this.props;
  //   const { slug } = article;
  //   const { URL: url, PARAMS: params } = ROUTELIST.ARTICLE;
  //   const urlTransformed: string = params.reduce((r: string, p: string) => {
  //     if(p === 'slug') { return r.replace(/(:slug)/g, slug); }
  //     return r;
  //   }, url);
  //   // 
  //   history.push(urlTransformed);
  // }

  renderList = (article: MediumArticle) => {
    return (
      <Col key={`article-wrapper-${article.id}`} xs="12" sm="6" lg="4" className={styles.articleWrapper}>
        <ArticleUnit
          key={`article-unit-${article.id}`}
          article={article}
          // onClick={this.handleOnClickToViewArticle}
          onClick={() => actionViewArticle(article)(this.props.history)}
        />
      </Col>
    );
  }

  render() {
    const { articles } = this.props;
    if (Array.isArray(articles) && articles.length > 0) {
      return (
        <div className={styles.wrapper}>
          <Views.Title />
          <Row>
            { this.props.articles.map(this.renderList) }
          </Row>
        </div>
      );
    }
    return null;
  }
}

export default withRouter(SectionInsights);