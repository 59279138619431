import { Expert } from 'models/Expert';
import { StoreStatusTypes } from 'common/constants/store';

export enum ActionTypes {
  GetListExperts = 'GET_LIST_EXPERT',
};

// 
export interface ListExperts {
  experts:                    Expert[],
  expertsUpdatedAt:           string,
}

export interface StoreListExperts extends ListExperts {
  isGettingListExperts:       boolean,
  getListExpertsStatus:       StoreStatusTypes,
  getListExpertsErrorMessage: '',
}

export interface State extends
  StoreListExperts
{}