import { isEmpty } from 'lodash';
import { RequestFormData } from './RequestForm.models';

export const validate = (data: RequestFormData): boolean => {
  return (
    !isEmpty(data)
  && !isEmpty(data.firstName) && data.firstName.length > 0
  && !isEmpty(data.lastName) && data.lastName.length > 0
  && !isEmpty(data.email) && data.email.length > 0
  && !isEmpty(data.message) && data.message.length > 0
  // && !isEmpty(data.preferredLanguage) && data.preferredLanguage.length > 0
  // && !isEmpty(data.preferredDates) && data.preferredDates.length > 0
  );
}