// import { getReducerData } from 'common/libs/reducer';
import { ActionStore, ReducerKeyMapping } from 'models/Store';
import { ActionStates, StoreStatusTypes } from 'common/constants/store';
import { ActionTypes, State } from './language.models';
import { INIT_STATE } from './language.constants';

const getTranslation = (state: State, action: ActionStore, keys: ReducerKeyMapping) => {
  switch (action.state) {
    case ActionStates.Start:
      return {
        ...state,
        [keys.loading]: true,
        [keys.statusType]: StoreStatusTypes.Undefined,
      };
    case ActionStates.Success:
      return {
        ...state,
        ...action.payload,
        [keys.loading]: false,
        [keys.statusType]: StoreStatusTypes.Success,
      };
    case ActionStates.Failure:
      return {
        ...state,
        [keys.loading]: false,
        [keys.statusType]: StoreStatusTypes.Error,
        [keys.errorMessage]: action.payload,
      };
    case ActionStates.Reset:
      return { ...state };
    default:
      return state;
  }
}

const updateLocale = (state: State, action: ActionStore) => {
  switch (action.state) {
    case ActionStates.Success:
      return {
        ...state,
        locale: action.payload,
      };
    default:
      return state;
  }
};

export default (state: State = INIT_STATE, action: ActionStore) => {
  switch (action.type) {
    case ActionTypes.UpdateLocale:
      return updateLocale(state, action);
    case ActionTypes.GetTranslation:
      return getTranslation(state, action, {
        loading:      'isGettingTranslation',
        statusType:   'getTranslationStatus',
        errorMessage: 'getTranslationErrorMessage',
      } as ReducerKeyMapping);
    default:
      return state;
  }
};
