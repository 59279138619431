import { Props, State } from './VerifyUser.models';
import ERRORS from 'common/constants/errors';

export const INIT_PROPS: Props = Object.freeze({
} as Props);

export const INIT_STATE: State = Object.freeze({
  isMissingKey: false,
} as State);

export const MESSAGE = {
  ERROR: {
    TITLE: 'Error',
    DESC: ERRORS.DEFAULT,
  },
  THANKS: {
    TITLE: 'Thank you!',
    DESC: 'Your email address has been confirmed.',
  },
  INVALID: {
    TITLE: 'Invalid',
    DESC: 'Invalid verification code.',
  },
}