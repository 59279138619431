import { get } from 'lodash';
import { createTransform } from 'redux-persist';
import { transformPersistData } from 'common/libs/gsheet';
import { MODULE_NAME as gsheetModuleName } from './gsheet';

export const transformGsheetsData = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => inboundState,
  // transform state being rehydrated
  // @ts-ignore
  (outboundState: object, key: string) => {
    return {
      ...outboundState,
      experts: transformPersistData(get(outboundState, 'experts', []))
    };
  },
  // define which reducers this transform gets called for.
  { whitelist: [gsheetModuleName] }
);
