import React from 'react';
import { HelmetTitle } from 'common/libs/helmet';
import { Container } from 'react-bootstrap';
import { ROUTELIST } from 'routes';
import { Layout } from 'components';
import Header from './Header';
import RequestForm from './RequestForm';
import styles from './ContactXpersity.module.scss';

export default class ContactXpersity extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HelmetTitle title={ROUTELIST.CONTACT_XPERSITY.NAME} />
        <Layout>
          <Container className={styles.wrapper}>
            <Header />
            <RequestForm />
          </Container>
        </Layout>
      </React.Fragment>
    )
  }
}
