import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Layout } from 'components';

import { StoreStatusTypes } from 'common/constants/store';
import { actionSubscribeForFreeReport, actionSubscribeProduct } from 'store/subscription';
import { StateFromStore } from 'store/models';

import { ModalMessage } from 'components/ModalComponents';
import FormInformation from './FormInformation';
import FormStripe from './FormStripe';

import { OwnActions, Props, State } from './ArticleSubscription.models';
import { INIT_PROPS, INIT_STATE } from './ArticleSubscription.constants';
import {
  Image,
  ButtonSubmitContent as SubmitText,
  ButtonSubmittingContent as SubmittingText,
  ButtonCheckoutContent as CheckoutText,
  SuccessMessage,
  ErrorMessage,
} from './ArticleSubscription.views';
import styles from './ArticleSubscription.module.scss';
import {loadStripe} from '@stripe/stripe-js';

class ArticleSubscription extends React.Component<Props, State> {
  static defaultProps: Props = INIT_PROPS;
  state: State = INIT_STATE;

  componentDidUpdate = (prevProps: Props) => {
    const [newIsSubscribeForFreeReport, oldIsSubscribeForFreeReport] =
      [this.props.isSubscribeForFreeReport, prevProps.isSubscribeForFreeReport];
    if (newIsSubscribeForFreeReport !== oldIsSubscribeForFreeReport && !newIsSubscribeForFreeReport) {
      this.setState({ isShowMessage: true });
    }

    const [newIsSubscribingProduct, oldIsSubscribingProduct] =
      [this.props.isSubscribingProduct, prevProps.isSubscribingProduct];
    if (newIsSubscribingProduct !== oldIsSubscribingProduct && !newIsSubscribingProduct) {
      this.handleToCheckoutForm(this.props.subscribeSessionId, this.props.subscribeStripePublicKey);
    }
  }

  isSubscriptionProduct = () => {
    const queryParams = new URLSearchParams(this.props.location.search);

    return (queryParams.get('id')
      && `${queryParams.get('id')}`.length > 0
      && queryParams.get('priceId')
      && `${queryParams.get('priceId')}`.length > 0
    );
  }

  allowSubmit = (): boolean => {
    const { formData, formError } = this.state;
    return (
      formError.length <= 0
      && formData.firstName.length > 0
      && formData.lastName.length > 0
      && formData.email.length > 0
      && !this.props.isSubscribeForFreeReport
      && !this.props.isSubscribingProduct
    );
  }

  // GETTERS
  getContent = () => {
    switch(this.props.subscribeForFreeReportStatus) {
      case StoreStatusTypes.Success:
        return (<SuccessMessage />);
      case StoreStatusTypes.Error:
        return (<ErrorMessage />);
    }
    return null;
  }

  handleOnSubmit = () => {
    if (this.allowSubmit()) {
      const { formData } = this.state;
      if (this.props.doSubscribeForFreeReport) {
        const queryParams = new URLSearchParams(this.props.location.search);
        this.props.doSubscribeForFreeReport({
          ...formData,
          articleId: queryParams.get('articleId'),
        });
      }
    }
  }

  handleOnCheckout = () => {
    if (this.allowSubmit()) {
      const queryParams = new URLSearchParams(this.props.location.search);
      const { formData } = this.state;
      if (this.props.doSubscribeProduct) {
        this.props.doSubscribeProduct({
          ...formData,
          productId: queryParams.get('id'),
          priceId: queryParams.get('priceId'),
          articleId: queryParams.get('articleId'),
        });
      }
    }
  }

  handleOnModalMessageClose = () => this.setState(
    { isShowMessage: false },
    (() => {
      if (this.props.subscribeForFreeReportStatus === StoreStatusTypes.Success) {
        document.querySelector('form')?.reset();
        this.setState({ ...INIT_STATE });
      }
    }).bind(this) // eslint-disable-line
  );

  handleToCheckoutForm = async (sessionId: string = '', publicKey: string = '') => {
    const stripe = await loadStripe(publicKey);
    // @ts-ignore
    stripe
      .redirectToCheckout({ sessionId })
      .then((err) => console.log(err));
  }

  renderSubmitButton = () => {
    if (!this.isSubscriptionProduct()) {
      return (
        <Button
          variant="primary"
          disabled={!this.allowSubmit()}
          onClick={() => this.handleOnSubmit()}
        >
          { this.props.isSubscribeForFreeReport ? (<SubmittingText />) : (<SubmitText />) }
        </Button>
      );
    }
    return null;
  }

  renderCheckoutButton = () => {
    if (this.isSubscriptionProduct()) {
      return (
        <Button
          variant="primary"
          disabled={!this.allowSubmit()}
          onClick={() => this.handleOnCheckout()}
        >
          { this.props.isSubscribeForFreeReport ? (<SubmittingText />) : (<CheckoutText />) }
        </Button>
      );
    }
    return null;
  }

  render() {
    const queryParams = new URLSearchParams(this.props.location.search);

    return (
      <React.Fragment>
        <Layout>
          <Container className={styles.wrapper}>
            <Row>
              <Col xs={12} md={5} className="mb-3">
                <Image />
              </Col>
              <Col xs={12} md={7} className="mb-3">
                <FormInformation
                  onChange={(data) => this.setState({ formData: data })}
                  onError={(s: string) => this.setState({ formError: s })}
                  disabled={this.props.isSubscribeForFreeReport}
                />
                {
                  this.isSubscriptionProduct()
                  ? (
                      <React.Fragment>
                        <div className="mt-3 mb-3">&nbsp;</div>
                        <FormStripe productId={queryParams.get('id') || ''} />
                      </React.Fragment>
                    )
                  : null
                }
                <hr />
                { this.renderSubmitButton() }
                { this.renderCheckoutButton() }
              </Col>
            </Row>
          </Container>
          <ModalMessage
            content={this.getContent()}
            show={this.state.isShowMessage}
            onHide={() => this.handleOnModalMessageClose()}
          />
        </Layout>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: StateFromStore) => ({
  isSubscribeForFreeReport:           state.subscription.isSubscribeForFreeReport,
  subscribeForFreeReportStatus:       state.subscription.subscribeForFreeReportStatus,
  subscribeForFreeReportErrorMessage: state.subscription.subscribeForFreeReportErrorMessage,
  //
  isSubscribingProduct:               state.subscription.isSubscribingProduct,
  subscribeProductStatus:             state.subscription.subscribeProductStatus,
  subscribeProductErrorMessage:       state.subscription.subscribeProductErrorMessage,
  subscribeSessionId:                 state.subscription.subscribeSessionId,
  subscribeStripePublicKey:           state.subscription.subscribeStripePublicKey,
});

const mapDispatchToProps = (dispatch): OwnActions => ({
  doSubscribeForFreeReport: (request: any) => dispatch(actionSubscribeForFreeReport(request)),
  doSubscribeProduct: (request: any) => dispatch(actionSubscribeProduct(request)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleSubscription);