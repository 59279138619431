import React from 'react';
import { pick, isEqual, isEmpty, xorWith } from 'lodash';
import { Form, Row, Col } from 'react-bootstrap';
import { AdviceNeedsData } from '../RequestForm.models';
import {
  // LIST_CHECKBOXES,
  INIT_PROPS, INIT_STATE, INIT_DATA,
  ON_CHANGED_TIMEOUT,
} from './AdviceNeeds.constants';
import {
  injectIntl,
  WrappedComponentProps,
  // MessageDescriptor,
} from 'react-intl';
import messages from './AdviceNeeds.messages';
import { Props, State } from './AdviceNeeds.models';
import styles from './AdviceNeeds.module.scss';

class AdviceNeeds extends React.Component<Props & WrappedComponentProps, State> {
  static defaultProps: Props = INIT_PROPS;
  state: State = INIT_STATE;
  onChangedTimeout: NodeJS.Timeout | null = null;

  // COMPONENT EVENTS
  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const newData = pick(this.state, Object.keys(INIT_DATA)) as AdviceNeedsData;
    const oldData = pick(prevState, Object.keys(INIT_DATA)) as AdviceNeedsData;
    if (!isEqual(newData.message, oldData.message)
        || !isEmpty(xorWith(newData.adviceNeeds, oldData.adviceNeeds, isEqual))
    ) {
      this.handleUpdateOnChangeTimeout(newData);
    }
  }

  // HANDLES
  // handleOnCheckboxClicked = (index: number) => {
  //   const { selectedIndexes } = this.state;
  //   let newSelectedIndexes: number[] = [];
  //   // 
  //   if (selectedIndexes.includes(index)) {
  //     newSelectedIndexes = selectedIndexes.filter(s => s !== index);
  //   } else {
  //     newSelectedIndexes = [...selectedIndexes, index];
  //   }
  //   //
  //   this.setState({
  //     selectedIndexes: newSelectedIndexes,
  //     adviceNeeds: LIST_CHECKBOXES
  //                   .filter((l, i) => newSelectedIndexes.includes(i))
  //                   .map(c => this.props.intl.formatMessage(c) as string),
  //   } as Pick<State, keyof State>);
  // }

  handleOnMessageChanged = (newValue: string) => {
    this.setState({
      message: newValue,
    } as Pick<State, keyof State>);
  }

  handleUpdateOnChangeTimeout = (newData: AdviceNeedsData) => {
    if (this.props.onChanged) {
      if (this.onChangedTimeout !== null) {
        clearTimeout(this.onChangedTimeout);
      }
      this.onChangedTimeout = setTimeout(
        (() => this.props.onChanged(newData)).bind(this), // eslint-disable-line no-extra-bind
        ON_CHANGED_TIMEOUT
      );
    }
  }

  // RENDERS
  // renderCheckbox = (message: MessageDescriptor, index: number) => {
  //   return (
  //     <Form.Check
  //       inline
  //       key={`advice-need-${index}`}
  //       type="checkbox"
  //       label={this.props.intl.formatMessage(message) as string}
  //       checked={this.state.selectedIndexes.includes(index)}
  //       onChange={() => this.handleOnCheckboxClicked(index)}
  //     />
  //   );
  // }

  render() {
    return (
      <Row>
        {/* <Col xs="12" className="mb-3">
          { LIST_CHECKBOXES.map(this.renderCheckbox) }
        </Col> */}
        <Col xs="12">
          <Form.Group controlId="message">
            <Form.Control
              required
              as="textarea"
              maxLength={1000}
              className={styles.textarea}
              placeholder={ this.props.intl.formatMessage(messages.messagePlaceHolder) as string }
              onChange={e => this.handleOnMessageChanged(e.target.value)}
            />
            <Form.Text className="text-muted">
              { this.props.intl.formatMessage(messages.messageNotice) }
            </Form.Text>
          </Form.Group>
        </Col>
      </Row>
    )
  }
}

export default injectIntl(AdviceNeeds);