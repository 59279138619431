import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'page.home.sectionWhoAreOurExperts.title',
    defaultMessage: 'Who are our experts',
  },
  'group[0].title': {
    id: 'page.home.sectionWhoAreOurExperts.group[0].title',
    defaultMessage: 'Lawyers',
  },
  'group[1].title': {
    id: 'page.home.sectionWhoAreOurExperts.group[1].title',
    defaultMessage: 'Consultants',
  },
  'group[2].title': {
    id: 'page.home.sectionWhoAreOurExperts.group[2].title',
    defaultMessage: 'General Managers',
  },
  'group[3].title': {
    id: 'page.home.sectionWhoAreOurExperts.group[3].title',
    defaultMessage: 'Industry Experts',
  },
  'group[4].title': {
    id: 'page.home.sectionWhoAreOurExperts.group[4].title',
    defaultMessage: 'Functional Executives',
  },
  'group[5].title': {
    id: 'page.home.sectionWhoAreOurExperts.group[5].title',
    defaultMessage: 'Software Engineers',
  },

  'group[0].content': {
    id: 'page.home.sectionWhoAreOurExperts.group[0].content',
    defaultMessage: 'From top-tier law firms',
  },
  'group[1].content': {
    id: 'page.home.sectionWhoAreOurExperts.group[1].content',
    defaultMessage: 'From McKinsey, BCG, Bain',
  },
  'group[2].content': {
    id: 'page.home.sectionWhoAreOurExperts.group[2].content',
    defaultMessage: 'From growth stage unicorns and "FAANG"',
  },
  'group[3].content': {
    id: 'page.home.sectionWhoAreOurExperts.group[3].content',
    defaultMessage: 'From gaming, payments, logistics, blockchain and more',
  },
  'group[4].content': {
    id: 'page.home.sectionWhoAreOurExperts.group[4].content',
    defaultMessage: 'From CTO to CFO to COO',
  },
  'group[5].content': {
    id: 'page.home.sectionWhoAreOurExperts.group[5].content',
    defaultMessage: 'From Python, Java, C++, React, ML/AI and more',
  },
})
