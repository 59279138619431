import React from 'react';
// import { RouteComponentProps } from 'react-router-dom';
import { isEqual } from 'lodash';
import { Row, Col, Form } from 'react-bootstrap';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import { Props, State } from './FormInformation.models';
import { INIT_PROPS, INIT_STATE } from './FormInformation.constants';
import { getErrorMessage } from './FormInformation.utils';
import styles from './FormInformation.module.scss';

class FormInformation extends React.Component<Props & WrappedComponentProps, State> {
  static defaultProps: Props = INIT_PROPS;
  state: State = INIT_STATE;
  refForm: React.RefObject<HTMLFormElement> = React.createRef<HTMLFormElement>();

  componentDidUpdate(prevProps: Props, prevState: State) {
    const [formData, oldFormData] = [this.state.formData, prevState.formData];
    if (!isEqual(formData.email, oldFormData.email)
      || !isEqual(formData.firstName, oldFormData.firstName)
      || !isEqual(formData.lastName, oldFormData.lastName)
    ) {
      const { formError } = this.state;
      const { onChange, onError } = this.props;
      onChange && onChange(formData);
      onError && onError(formError.firstName || formError.lastName || formError.email || '');
    }
  }

  reset = () => {
    this.setState(INIT_STATE);
  }

  handleOnValueChange = (key: string, newValue: string) => {
    const regexMap = {
      'firstName': /^.{1,50}$/,
      'lastName': /^.{1,50}$/,
      // 'email': /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/,
      'email': /^([a-zA-Z0-9_.\-+])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
    }
    const newFormData = { ...this.state.formData };
    const newFormError = { ...this.state.formError };

    newFormData[key] = newValue;
    if (!(regexMap[key] as RegExp).test(newValue)) {
      newFormError[key] = getErrorMessage(key);
    } else {
      newFormError[key] = '';
    }

    this.setState({ formData: newFormData, formError: newFormError });
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <h4 className="mb-3">Your Information</h4>
        <Form ref={this.refForm}>
          <Row>
            <Col xs="12" md="6">
              <Form.Group controlId="firstName" className="mb-1">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text" name="firstName" autoComplete="given-name" placeholder="First Name" required
                  disabled={this.props.disabled}
                  isInvalid={(this.state.formError.firstName.length > 0)}
                  isValid={(this.state.formData.firstName.length > 0 && this.state.formError.firstName.length <= 0)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleOnValueChange('firstName', e.target.value)}
                />
                { this.state.formError.firstName.length <= 0 && (<Form.Text className="text-muted">&nbsp;</Form.Text>) }
                <Form.Control.Feedback type="invalid">
                  { this.state.formError.firstName }
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs="12" md="6">
              <Form.Group controlId="lastName" className="mb-1">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text" name="lastName" autoComplete="family-name" placeholder="Last Name" required
                  disabled={this.props.disabled}
                  isInvalid={(this.state.formError.lastName.length > 0)}
                  isValid={(this.state.formData.lastName.length > 0 && this.state.formError.lastName.length <= 0)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleOnValueChange('lastName', e.target.value)}
                />
                { this.state.formError.lastName.length <= 0 && (<Form.Text className="text-muted">&nbsp;</Form.Text>) }
                <Form.Control.Feedback type="invalid">
                  { this.state.formError.lastName }
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs="12">
              <Form.Group controlId="email" className="mb-1">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" name="email" autoComplete="email" placeholder="Email" required
                  disabled={this.props.disabled}
                  isInvalid={(this.state.formError.email.length > 0)}
                  isValid={(this.state.formData.email.length > 0 && this.state.formError.email.length <= 0)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleOnValueChange('email', e.target.value)}
                />
                { this.state.formError.email.length <= 0 && (<Form.Text className="text-muted">&nbsp;</Form.Text>) }
                <Form.Control.Feedback type="invalid">
                  { this.state.formError.email }
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

export default injectIntl(FormInformation);