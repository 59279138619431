import React from 'react';
import cns from 'classnames';
import buildUrl from 'build-url';

import { ROUTELIST } from 'routes';
import { Button, CardGroup, Card } from 'react-bootstrap';
import styles from './SectionSubscription.module.scss';
import { Props } from './SectionSubscription.models';
import { INIT_PROPS, FEATURES } from './SectionSubscription.constants';
import { generateSubscriptionList } from './SectionSubscription.utils';

class SectionSubscription extends React.Component<Props> {
  static defaultProps: Props = INIT_PROPS;

  handleOnClickLink = (params: { id: string, priceId: string } = {id: '', priceId: ''}) => {
    const queryParams: any = { articleId: this.props.articleId }
    if (params.id) { queryParams.id = params.id; }
    if (params.id) { queryParams.priceId = params.priceId; }
    
    const link: string = buildUrl(ROUTELIST.ARTICLE_SUBSCRIPTION.URL, { queryParams });
    if (this.props.onClickLink) {
      this.props.onClickLink(link);
    }
  }

  renderMostPopular = (is: boolean = false) => {
    if (is) {
      return (
        <span className="badge badge-info p-2">
          <i className="fas fa-star mr-1" />Most popular
        </span>
      );
    }
    return (<span>&nbsp;</span>);
  }

  renderFirstMonthFree = (is: boolean = false) => {
    if (is) {
      return (<small>(free for first month)</small>);
    }
    return (<small>&nbsp;</small>);
  }

  renderFeature = (keyName: string, active: boolean = false) => {
    const name = FEATURES[keyName];
    if (active) {
      return (<p className="mb-2">{ name }</p>);
    }
    return (
      <p className="text-black-50 font-weight-light mb-2">
        <del>{ name }</del>
      </p>
    );
  }

  renderCard = (item: any, idx: number) => {
    const { hideFree, hideSubscription } = this.props;
    if ((hideFree && !item.id) || (hideSubscription && item.id)) {
      return null;
    }
    // 
    return (
      <Card key={`prod-${item.id}`} className={styles.card}>
        <Card.Header className={cns({[styles.zeroOpacity]: !item.isMostPopular})}>
          {this.renderMostPopular(item.isMostPopular)}
        </Card.Header>
        <Card.Body>
          <Card.Title>{item.title}</Card.Title>
          <p className={cns(`mb-0`, {[styles.zeroOpacity]: (item.price <= 0)})}>
            {`$${item.price}`} <sup>per month</sup>
          </p>
          <p className={cns(`text-muted mb-4`, {[styles.zeroOpacity]: !item.isFirstMonthFree})}>
            {this.renderFirstMonthFree(item.isFirstMonthFree)}
          </p>

          <p className="h6 text-uppercase text-secondary mb-1">features</p>
          { this.renderFeature('onetime_report', item.features.onetime_report) }
          { this.renderFeature('biweekly_report', item.features.biweekly_report) }
          { this.renderFeature('quarterly_podcast', item.features.quarterly_podcast) }
        </Card.Body>
        <Card.Footer>
          <Button
            type="button"
            variant="outline-primary w-100"
            onClick={() => this.handleOnClickLink({ id: item.id, priceId: item.priceId })}
          >
            { item.buttonText }
          </Button>
        </Card.Footer>
      </Card>
    )
  }

  render() {
    const cards = generateSubscriptionList(this.props.products);
    return (
      <CardGroup className={styles.wrapper}>
        { cards.map(this.renderCard) }
      </CardGroup>
    )
  }
}


export default SectionSubscription;