import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'page.home.sectionHero.title',
    defaultMessage: 'Find the next 10X opportunity in Asia',
  },
  description: {
    id: 'page.home.sectionHero.description',
    defaultMessage: 'Get perspective, insight and intellectual rigor from our experts to identify non-obvious investment and business opportunities from private to public markets',
  }
})