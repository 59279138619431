import React from 'react';
import { connect } from 'react-redux';
import { isEmpty, pick } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { StoreStatusTypes } from 'common/constants/store';
import { actionContactXpersity } from 'store/contact';
import { StateFromStore } from 'store/models';
import { ModalMessage } from 'components/ModalComponents';
import messages from './RequestForm.messages';
import Section from './Section';
import ContactInfo from './ContactInfo';
import AdviceNeeds from './AdviceNeeds';
// import TimeSlots from './TimeSlots';
import { 
  ContactInfoData,
  TimeSlotsData,
  AdviceNeedsData,
  RequestFormData,
  OwnActions,
  Props,
  State,
} from './RequestForm.models';
import { validate } from './RequestForm.utils';
import {
  INIT_PROPS, INIT_STATE,
  INIT_REQUEST_FORM_DATA,
} from './RequestForm.constants';
import {
  ExpertSection,
  SuccessMessage, ErrorMessage,
  ButtonSendingContent as SendingText, ButtonSendContent as SendText,
} from './RequestForm.views';

class RequestForm extends React.Component<Props, State> {
  static defaultProps: Props = INIT_PROPS;
  state: State = INIT_STATE;

  // COMPONENTS
  componentDidMount = () => {
    const params = new URLSearchParams(this.props.location.search);
    if (params.has('name')) {
      this.setState({ expertName: params.get('name') || '' });
    }
  }

  componentDidUpdate = (prevProps: Props) => {
    const [newIsContactingXpersity, oldIsContactingXpersity] =
      [this.props.isContactingXpersity, prevProps.isContactingXpersity];
    if (newIsContactingXpersity !== oldIsContactingXpersity && !newIsContactingXpersity) {
      this.setState({ isShowMessage: true });
    }
  }

  // UTILITIES
  isAllowToSubmit = () => {
    const data = pick(this.state, Object.keys(INIT_REQUEST_FORM_DATA)) as RequestFormData;
    return validate(data) && !this.props.isContactingXpersity;
  }

  // GETTERS
  getContent = () => {
    switch(this.props.contactXpersityStatus) {
      case StoreStatusTypes.Success:
        return (<SuccessMessage />);
      case StoreStatusTypes.Error:
        return (<ErrorMessage />);
    }
    return null;
  }

  // HANDLES
  handleOnDataChanged = (newData: ContactInfoData | TimeSlotsData | AdviceNeedsData) => {
    if (!isEmpty(newData)) {
      this.setState({ ...newData } as Pick<State, keyof State>);
    }
  }

  handleOnPressSubmit = () => {
    const data = pick(this.state, Object.keys(INIT_REQUEST_FORM_DATA)) as RequestFormData;
    if (this.isAllowToSubmit()) {
      this.props.doContactXpersity(data);
    }
  }

  handleOnModalMessageClose = () => this.setState(
    { isShowMessage: false },
    (() => {
      if (this.props.contactXpersityStatus === StoreStatusTypes.Success) {
        document.querySelector('form')?.reset();
        this.setState({ ...INIT_STATE });
      }
    }).bind(this) // eslint-disable-line
  );

  render() {
    return (
      <React.Fragment>
        <Form onSubmit={e => { this.handleOnPressSubmit(); e.preventDefault(); }}>
          <fieldset disabled={this.props.isContactingXpersity}>
            
            <Section no={1} title={messages.yourContactInformation}>
              <ContactInfo
                onChanged={this.handleOnDataChanged}
              />
            </Section>
            {/* <Section no={2} title={messages.yourPreferredSchedule}>
              <TimeSlots
                onChanged={this.handleOnDataChanged}
              />
            </Section> */}
            <Section no={2} title={messages.yourAdviceNeeds}>
              <AdviceNeeds
                onChanged={this.handleOnDataChanged}
              />
            </Section>
            {
              this.state.expertName
              ? (<ExpertSection no={3} title={messages.expertInformation} expertName={this.state.expertName} />)
              : null
            }
            <Button
              type="submit"
              variant="primary"
              disabled={!this.isAllowToSubmit()}
            >
              { this.props.isContactingXpersity ? (<SendingText />) : (<SendText />) }
            </Button>
          </fieldset>
        </Form>
        <ModalMessage
          content={this.getContent()}
          show={this.state.isShowMessage}
          onHide={() => this.handleOnModalMessageClose()}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: StateFromStore) => ({
  isContactingXpersity:   state.contact.isContactingXpersity,
  contactXpersityStatus:  state.contact.contactXpersityStatus,
  contactXpersityError:   state.contact.contactXpersityErrorMessage,
});

const mapDispatchToProps = (dispatch): OwnActions => ({
  doContactXpersity: (request: RequestFormData) => dispatch(actionContactXpersity(request)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RequestForm));