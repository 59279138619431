import React from 'react';
// import { Row, Col, Container, Form } from 'react-bootstrap';
import styles from './FormStripe.module.scss';

interface Props {
  productId: string,
}

export default class FormStripe extends React.Component<Props> {
  static defaultProps: Props = {
    productId: '',
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <h4>Payment Information</h4>
        <p className="mb-3"><small>All transaction are secure and encrypted</small></p>
        <p className="mb-0"><b>Subscription</b>: $5.99 <sup>per month</sup></p>
        <p className="text-muted">
          <small>&bull; Free for first month &bull; Report to your inbox &bull; Bi-weekly report &bull; Quarterly podcast</small>
        </p>
      </div>
    )
  }
}
