import React from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { Row, Col, Form } from 'react-bootstrap';
import messages from './RequestForm.messages';
import Section from './Section';

export const ExpertSection = ({no, title , expertName }) => {
  const intl = useIntl();
  return (
    <Section no={no} title={title}>
      <Row>
        <Col xs="12" md="9" lg="6">
          <Form.Group className="mb-4">
            <Form.Label>{ intl.formatMessage(messages.expertName) }</Form.Label>
            <Form.Control
              readOnly
              as="input"
              type="text"
              maxLength={255}
              placeholder={ intl.formatMessage(messages.expertName) as string }
              value={expertName}
            />
          </Form.Group>
        </Col>
      </Row>
    </Section>
  );
};

export const SuccessMessage = injectIntl(({intl}) => (
  <React.Fragment>
    <h6 className="text-center mb-2">{ intl.formatMessage(messages.successMessageTitle) }</h6>
    <p className="text-center mb-0">{ intl.formatMessage(messages.successMessageDescription) }</p>
  </React.Fragment>
));

export const ErrorMessage = injectIntl(({intl}) => (
  <React.Fragment>
    <h6 className="text-center mb-2">{ intl.formatMessage(messages.errorMessageTitle) }</h6>
    <p className="text-center text-danger mb-0">{ intl.formatMessage(messages.errorMessageDescription) }</p>
  </React.Fragment>
));

export const ButtonSendingContent = injectIntl(({intl}) => (
  <React.Fragment>
    <i className="fas fa-spinner fa-pulse mr-1" />
    <span className="d-inline-block capitalize-first-letter">
      { intl.formatMessage(messages.sendingButton) }
    </span>
  </React.Fragment>
));

export const ButtonSendContent = injectIntl(({intl}) => (
  <React.Fragment>
    <i className="fas fa-paper-plane mr-1" />
    <span className="d-inline-block capitalize-first-letter">
      { intl.formatMessage(messages.sendButton) }
    </span>
  </React.Fragment>
));
