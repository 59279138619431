import React from 'react';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import { HelmetTitle } from 'common/libs/helmet';
import { getFormattedTime } from 'common/libs/moment';
import { StoreStatusTypes } from 'common/constants/store';
import { Layout, BasicTextLoading } from 'components';

import { StateFromStore } from 'store/models';
import { actionGetArticle } from 'store/article';
import { actionGetProducts } from 'store/subscription';

import Header from './Header';
import SectionSubscription from './SectionSubscription';
import { NoContentAvailable } from './PaidArticleDetail.views';
import {
  isAPaidArticle,
  getArticleIDFromSlug,
  getFirstHeadingFromContent,
} from './PaidArticleDetail.utils';
import { Props, State, OwnActions } from './PaidArticleDetail.models';
import { INIT_PROPS, INIT_STATE } from './PaidArticleDetail.constants';
import styles from './PaidArticleDetail.module.scss';

class PaidArticleDetail extends React.Component<Props, State> {
  static defaultProps = INIT_PROPS;
  state = INIT_STATE;

  componentDidMount = () => {
    const slug = get(this.props, 'match.params.slug', '');
    const id = getArticleIDFromSlug(slug);
    if (id && this.props.doGetArticle) {
      this.setState(
        { articleId: id },
        () => this.props.doGetArticle(id),
      );
    }
    if (this.props.doGetProducts) {
      this.props.doGetProducts();
    }
  }

  handleOnClickLink = (link: string) => {
    if (link && this.props.history && this.props.history.push) {
      this.props.history.push(link);
    }
  }

  renderArticleDetail = (firstHeading: string) => {
    const { article } = this.props;
    
    return (
      <React.Fragment>
        <h1 className={styles.title}>{ article.title }</h1>
        <p className={styles.moreInfo}>
          {/* <span>
            Published on { getFormattedTime(article.publishedAt) } by <span className={styles.author}>{ article.creator }</span>
          </span> */}
          <span>
            Published on { getFormattedTime(article.publishedAt) }
          </span>
        </p>
        <hr />
        <div className={styles.content} dangerouslySetInnerHTML={{__html: article.content.replace(firstHeading, '')}} />
      </React.Fragment>
    )
  }

  renderContent = (firstHeading: string) => {
    const { isGettingArticleDetail, getArticleDetailStatus, article } = this.props;
    if (isGettingArticleDetail) {
      return (<BasicTextLoading />);
    } else if (getArticleDetailStatus === StoreStatusTypes.Error) {
      return (<NoContentAvailable />);
    } else if (!isGettingArticleDetail && !isEmpty(article)) {
      return this.renderArticleDetail(firstHeading);
    }
    return '';
  }

  render() {
    const { article } = this.props;
    const firstHeading = getFirstHeadingFromContent(article.content);
    const paidArticle = isAPaidArticle(firstHeading);

    return (
      <React.Fragment>
        { this.props.article.title && (<HelmetTitle title={this.props.article.title} />) }
        <Layout>
          <Container className={styles.wrapper}>
            <Row>
              <Col xs={12} md={8} lg={9} className="mb-3">
                <Header />
                { this.renderContent(firstHeading) }
              </Col>
              <Col xs={12} md={4} lg={3} className="mb-3">
                {
                  this.props.isGettingProducts
                  ? <BasicTextLoading />
                  : (
                      <SectionSubscription
                        products={this.props.products}
                        articleId={this.state.articleId}
                        onClickLink={this.handleOnClickLink}
                        hideFree={paidArticle}
                        hideSubscription={!paidArticle}
                      />
                    )
                }
              </Col>
            </Row>
          </Container>
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: StateFromStore) => ({
  isGettingProducts:            state.subscription.isGettingProducts,
  getProductsStatus:            state.subscription.getProductsStatus,
  getProductsErrorMessage:      state.subscription.getProductsErrorMessage,
  products:                     state.subscription.products,
  // 
  article:                      state.article.article,
  isGettingArticleDetail:       state.article.isGettingArticleDetail,
  getArticleDetailStatus:       state.article.getArticleDetailStatus,
  getArticleDetailErrorMessage: state.article.getArticleDetailErrorMessage,
});

const mapDispatchToProps = (dispatch): OwnActions => ({
  doGetProducts: () => dispatch(actionGetProducts()),
  doGetArticle: (id: string) => dispatch(actionGetArticle(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(PaidArticleDetail)));