import React from 'react';
import moment from 'moment';
import { omit } from 'lodash';
import { ModalBody, ModalFooter, Button, ModalProps } from 'react-bootstrap';
import { removeTime } from 'common/libs/moment';
import { AutoFormatMessage } from 'common/libs/intl';
import { APP_MESSAGES } from 'common/constants/languages';
import DatePicker from 'react-datepicker';
import ModalWrapper from '../ModalWrapper';
import { Props, State } from './ModalDatePicker.models';
import { INIT_PROPS, INIT_STATE } from './ModalDatePicker.constants';
import styles from './ModalDatePicker.module.scss';

export default class ModalDatePicker extends React.Component<Props & ModalProps, State> {
  static defaultProps: Props = INIT_PROPS;
  state: State = INIT_STATE;

  getModalProps = () => {
    const disallowKeys = [...Object.keys(INIT_PROPS)];
    return omit(this.props, disallowKeys);
  }

  getDatePickerProps = () => {
    const { selected: propsSelected, minDate, maxDate } = this.props;
    const { pickerSelected } = this.state;
    let props: {[k: string]: any} = {
      selected: removeTime(pickerSelected || propsSelected || minDate || moment()).toDate(),
    };

    if (minDate) {
      props.minDate = removeTime(minDate).toDate();
    }
    if (maxDate) {
      props.maxDate = removeTime(maxDate).toDate();
    }
    
    return props;
  }

  handleOnEnter = () => {
    this.setState({ ...INIT_STATE })
  }

  handleOnPressSelected = () => {
    const { selected: propsSelected, minDate, onSelected, autoClose, onHide } = this.props;
    const { pickerSelected } = this.state;
    const selectedDate = removeTime(pickerSelected || propsSelected || minDate || moment()).format();
    // 
    onSelected && onSelected(selectedDate);
    if (autoClose && onHide) {
      onHide();
    }
  }

  render() {
    return (
      <ModalWrapper
        size="sm"
        title={<AutoFormatMessage message={APP_MESSAGES.picker} />}
        {...this.getModalProps()}
        dialogClassName={styles.dialog}
        onEnter={() => this.handleOnEnter()}
      >
        <ModalBody className="p-0">
          <DatePicker
            {...this.getDatePickerProps()}
            inline
            disabledKeyboardNavigation
            calendarClassName={styles.picker}
            onChange={(date: any) => this.setState({ pickerSelected: removeTime(date).format() })}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            size="sm"
            variant="primary"
            className="capitalize-first-letter"
            onClick={() => this.handleOnPressSelected()}
          >
            <AutoFormatMessage message={APP_MESSAGES.choose} />
          </Button>
        </ModalFooter>
      </ModalWrapper>
    )
  }
}
