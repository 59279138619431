import buildUrl from 'build-url';
import {
  API_URL as BASE_API_URL,
} from './env';
// ---

// this block define all url link
// ? URL is fixed version will no need version
const API_OPTION = buildUrl(BASE_API_URL, { path: 'option' });
export const API_ALL_OPTIONS = buildUrl(API_OPTION, { path: 'all' });

// ? User
export const API_USER = buildUrl(BASE_API_URL, { path: 'user' });

// ? Google sheets
const BASE_SPREADSHEETS_URL = buildUrl(BASE_API_URL, { path: `/gsheet/sheet` });

export const GSHEET_LIST_EXPERTS = buildUrl(BASE_SPREADSHEETS_URL, { path: '1292677433' });
export const GSHEET_TRANSLATION = buildUrl(BASE_SPREADSHEETS_URL, { path: '334715629' });

// ? Contact
export const API_CONTACT_XPERSITY = buildUrl(BASE_API_URL, { path: 'contact-xpersity' });

// ? Article
const ARTICLE_BASE_URL = buildUrl(BASE_API_URL, { path: 'article' });
export const ARTICLE_MEDIUM_URL = buildUrl(ARTICLE_BASE_URL, { path: 'medium' });
export const ARTICLE_DETAIL_URL = buildUrl(ARTICLE_BASE_URL, { path: ':id/detail' });
// export const ARTICLE_MEDIUM_TAGGED_URL = buildUrl(ARTICLE_MEDIUM_URL, { path: 'tagged' });

// ? Subscription
const SUBSCRIPTION_BASE_URL = buildUrl(BASE_API_URL, { path: 'subscription' });
export const SUBSCRIPTION_PRODUCTS = buildUrl(SUBSCRIPTION_BASE_URL, { path: 'products' });
export const SUBSCRIPTION_FREE_REPORT = buildUrl(SUBSCRIPTION_BASE_URL, { path: 'free-report' });
export const SUBSCRIPTION_CREATE_CHECKOUT_SESSION = buildUrl(SUBSCRIPTION_BASE_URL, { path: 'create-checkout-session' });
export const SUBSCRIPTION_UPDATE_SUBSCRIPTION = buildUrl(SUBSCRIPTION_BASE_URL, { path: 'update-subscription' });
