import React from 'react';
import { AutoFormatMessage } from 'common/libs/intl';
import messages from './SectionWhoAreOurExperts.messages';

export const Title = () => (
  <h2 className="mb-4 text-center text-md-left">
    <AutoFormatMessage message={messages.title} />
  </h2>
);

export const GroupTitle = ({ index }) => (
  <h4 className="text-center text-md-left">
    <AutoFormatMessage message={messages[`group[${index}].title`]} />
  </h4>
);

export const GroupContent = ({ index }) => (
  <p className="text-center text-md-left">
    <AutoFormatMessage message={messages[`group[${index}].content`]} />
  </p>
);
