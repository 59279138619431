import React from 'react';
import { AutoFormatMessage } from 'common/libs/intl';
import messages from './SectionUseCases.messages';
import styles from './SectionUseCases.module.scss';

export const Title = () => (
  <h2 className="mb-4 text-center text-md-left">
    <AutoFormatMessage message={messages.title} />
  </h2>
);

export const UseCaseTitle = ({ index }) => (
  <h4 className={styles.useCaseTitle}>
    <AutoFormatMessage message={messages[`useCase[${index}].title`]} />
  </h4>
);

export const UseCaseContent = ({ index }) => (
  <p className={styles.useCaseDescription}>
    <AutoFormatMessage message={messages[`useCase[${index}].content`]} />
  </p>
);
