import {
  GsheetExpertEducation,
  GsheetExpertExperience,
  isGsheetExpertEducation,
  isGsheetExpertExperience,
} from 'models/GsheetExpert';
import { History } from './MoreDetail.models';
import { isEmpty } from 'lodash';

const normalizeListExpertEducation = (list: GsheetExpertEducation[] = []): History[] => {
  // const formatString = 'YYYY';
  return list.reduce((prevResult: History[], e: GsheetExpertEducation) => {
    prevResult.push({
      title: e.university,
      description: e.major,
      period: e.year,
    } as History);
    return prevResult;
  }, []);
}

const normalizeListExpertExperience = (list: GsheetExpertExperience[] = []): History[] => {
  // const formatString = 'MMM YYYY';
  return list.reduce((prevResult: History[], e: GsheetExpertExperience) => {
    prevResult.push({
      title: !isEmpty(e.location) ? `${e.company} (${e.location})` : e.company,
      description: e.title,
      period: e.year,
    } as History);
    return prevResult;
  }, []);
}

export const normalizeListObjects = (list: GsheetExpertEducation[] | GsheetExpertExperience[] = []): History[] => {
  if (list.length > 0) {
    if (isGsheetExpertEducation(list[0])) {
      // @ts-ignore
      return normalizeListExpertEducation(list);
    } else if (isGsheetExpertExperience(list[0])) {
      // @ts-ignore
      return normalizeListExpertExperience(list);
    }
  }

  return [];
}