import React from 'react';
import { NAME as APP_NAME } from 'common/constants/info';
import { brandLogo as imgBrandLogo } from 'common/assets/logo';
import styles from './BrandLogo.module.scss';

export default class BrandLogo extends React.Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <img className={styles.image} src={imgBrandLogo} alt={APP_NAME} />
      </div>
    )
  }
}
