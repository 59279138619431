import { defineMessages } from 'react-intl';

export const LANGUAGES = [
  { locale: 'ko', desc: '한국어' },
  { locale: 'en', desc: 'english' },
];

export const APP_MESSAGES = defineMessages({
  insights: {
    id: 'app.insights',
    defaultMessage: 'insights',
  },
  findYourExpert: {
    id: 'app.findYourExpert',
    defaultMessage: 'find your expert',
  },
  becomeAnExpert: {
    id: 'app.becomeAnExpert',
    defaultMessage: 'become an expert',
  },
  termOfService: {
    id: 'app.termOfService',
    defaultMessage: 'Term of Service',
  },
  privacyPolicy: {
    id: 'app.privacyPolicy',
    defaultMessage: 'Privacy Policy',
  },
  contactXpersity: {
    id: 'app.contactXpersity',
    defaultMessage: 'Contact Xpersity',
  },
  send: {
    id: 'app.send',
    defaultMessage: 'send',
  },
  sending: {
    id: 'app.sending',
    defaultMessage: 'sending',
  },
  loading: {
    id: 'app.loading',
    defaultMessage: 'loading',
  },
  firstName: {
    id: 'app.firstName',
    defaultMessage: 'First Name',
  },
  lastName: {
    id: 'app.lastName',
    defaultMessage: 'Last Name',
  },
  email: {
    id: 'app.email',
    defaultMessage: 'Email',
  },
  yourTimezone: {
    id: 'app.yourTimezone',
    defaultMessage: 'Your Timezone',
  },
  faq: {
    id: 'app.faq',
    defaultMessage: 'FAQ',
  },
  message: {
    id: 'app.message',
    defaultMessage: 'message',
  },
  close: {
    id: 'app.close',
    defaultMessage: 'close',
  },
  choose: {
    id: 'app.choose',
    defaultMessage: 'choose',
  },
  chooseADate: {
    id: 'app.chooseADate',
    defaultMessage: 'choose a date',
  },
  viewDetail: {
    id: 'app.viewDetail',
    defaultMessage: 'view detail',
  },
  experiences: {
    id: 'app.experiences',
    defaultMessage: 'experiences',
  },
  errorDefault: {
    id: 'app.errorDefault',
    defaultMessage: 'an error occurs, please try again later',
  },
  picker: {
    id : 'app.picker',
    defaultMessage: 'picker',
  },
  readmore: {
    id : 'app.readmore',
    defaultMessage: 'Read More',
  },
  submit: {
    id : 'app.submit',
    defaultMessage: 'submit',
  },
  submitting: {
    id : 'app.submitting',
    defaultMessage: 'submitting',
  },
});
