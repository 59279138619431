import { ONE_PIXEL_TRANSPARENT_IMAGE } from 'common/constants/image';
import { GsheetExpert } from 'models/GsheetExpert';
import { Props } from './ExpertShortDetail.models';

export const DEFAULT_IMAGE = ONE_PIXEL_TRANSPARENT_IMAGE;

export const INIT_PROPS: Props = Object.freeze({
  className:      '',
  expertDetail:   new GsheetExpert({}),
  hideReadMore:   false,
  hideLocations:  false,
  hideExpertises: false,
  // 
  onClick:        () => {},
});