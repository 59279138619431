import { Props, State } from './PaidArticleDetail.models';

export const INIT_PROPS: Props = Object.freeze({
} as Props);

export const INIT_STATE: State = Object.freeze({
  articleId: '',
  firstHeading: '',
  hideFree: false,
  hideSubscription: false,
} as State);