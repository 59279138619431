import {
  State,
  StoreListExperts,
} from './gsheet.models';
import { StoreStatusTypes } from 'common/constants/store';

export const MODULE_NAME = 'gsheet';

export const INIT_LIST_EXPERTS: StoreListExperts = Object.freeze({
  isGettingListExperts:       false,
  getListExpertsStatus:       StoreStatusTypes.Undefined,
  experts:                    [],
  expertsUpdatedAt:           '',
  getListExpertsErrorMessage: '',
} as StoreListExperts);

export const INIT_STATE: State = Object.freeze({
  ...INIT_LIST_EXPERTS,
});