import {
  State,
  StoreTranslation,
  StoreLocale,
} from './language.models';
import { DEFAULT_LOCALE } from 'common/constants/env';
import { StoreStatusTypes } from 'common/constants/store';
import preKoTranslation from './pre-data.ko.json';

export const MODULE_NAME = 'language';

export const INIT_TRANSLATION: StoreTranslation = Object.freeze({
  isGettingTranslation:       false,
  getTranslationStatus:       StoreStatusTypes.Undefined,
  translationMessageGroup:    { en: {}, ko: preKoTranslation },
  updatedAt:                  '',
  getTranslationErrorMessage: '',
} as StoreTranslation);

export const INIT_LANGUAGE: StoreLocale = Object.freeze({
  locale: DEFAULT_LOCALE,
} as StoreLocale);

export const INIT_STATE: State = Object.freeze({
  ...INIT_TRANSLATION,
  ...INIT_LANGUAGE,
});