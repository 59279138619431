import {
  State,
  StoreContactXpersity,
} from './contact.models';
import { StoreStatusTypes } from 'common/constants/store';

export const MODULE_NAME = 'contact';

export const INIT_CONTACT_XPERSITY: StoreContactXpersity = Object.freeze({
  isContactingXpersity:         false,
  contactXpersityStatus:        StoreStatusTypes.Undefined,
  contactXpersityErrorMessage:  '',
} as StoreContactXpersity);

export const INIT_STATE: State = Object.freeze({
  ...INIT_CONTACT_XPERSITY,
});