import { actionCallAPI } from 'common/libs/action';
import { ActionTypes } from './user.models';
import * as userServices from 'services/user';

export const actionVerify = (code: string, actionName: ActionTypes = ActionTypes.VerifyUser) => (dispatch) => {
  const asyncAction = actionName === ActionTypes.VerifyUser
                      ? userServices.verifyUser(code)
                      : userServices.verifyExpert(code);
  const actions = {
    success: async (response) => {
      let result: any | null = null;
      const { status } = response;
      if (status === 200) {
        result = {};
      }
      return result;
    },
    error: (response) => {
      const { status, data } = response;
      return data.message || status;
    }
  };

  return dispatch(actionCallAPI(actionName, asyncAction, actions));
};

export const actionVerifyExpert = (code: string) => actionVerify(code, ActionTypes.VerifyExpert);