import React from 'react';
import classnames from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { USE_CASE_ICONS } from './SectionUseCases.constants';
import * as Views from './SectionUseCases.views';
import styles from './SectionUseCases.module.scss';

export default class SectionUseCases extends React.Component {
  renderUseCase = (useCaseIcon: string, idx: number) => {
    return (
      <Col key={`use-case-${idx}`} className={styles.useCase} xs="12" md="4">
        <Row className="align-items-start">
          <Col xs="auto" className="pt-2">
            <i className={ classnames([useCaseIcon, styles.useCaseIcon]) } />
          </Col>
          <Col className="pl-0">
            <Views.UseCaseTitle index={idx} />
          </Col>
        </Row>
        <Views.UseCaseContent index={idx} />
      </Col>
    );
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <Views.Title />
        <Row className={styles.useCaseWrapper}>
          { USE_CASE_ICONS.map(this.renderUseCase) }
        </Row>
      </div>
    )
  }
}
