const ROUTELIST = {
  HOME: {
    KEY:  'home',
    URL:  '/',
    NAME: 'Home',
  },
  INSIGHTS_MEDIUM: {
    KEY:  'insights_medium',
    URL:  '/insights',
    NAME: 'Insights',
  },
  INSIGHTS_NAVER: {
    KEY:  'insights_naver',
    URL:  'https://post.naver.com/xpersity',
    NAME: 'Insights',
  },
  EXPERTS: {
    KEY:  'experts',
    URL:  '/experts',
    NAME: 'Our Experts',
  },
  EXPERT_DETAIL: {
    KEY:  'expert detail',
    URL:  '/expert/:id',
    NAME: 'Experts',
    PARAMS: ['id'],
  },
  CONTACT_XPERSITY: {
    KEY:  'contact_xpersity',
    URL:  '/contact-xpersity',
    NAME: 'Contact Xpersity',
  },
  TERM: {
    KEY:  'terms',
    URL:  '/terms',
    NAME: 'Term Of Service',
  },
  PRIVACY: {
    KEY:  'privacy',
    URL:  '/privacy',
    NAME: 'Privacy Policy',
  },
  EXPERT_REGISTRATION: {
    KEY:  'expert_registration',
    URL:  '/register',
    NAME: 'Expert Registration',
  },
  EXPERT_REGISTRATION_BY_EMAIL: {
    KEY:  'expert_registration_by_email',
    URL:  '/formRegister',
    NAME: 'Expert Registration by Email',
  },
  EXPERT_REGISTRATION_BY_LINKEDIN: {
    KEY:  'expert_registration_by_linkedin',
    URL:  '/formRegisterLinkedin',
    NAME: 'Expert Registration by LinkedIn',
  },
  VERIFY_USER: {
    KEY:  'verify_user',
    URL:  '/verifyUser',
    NAME: 'Verify User',
  },
  VERIFY_EXPERT: {
    KEY:  'verify_expert',
    URL:  '/verifyExpert',
    NAME: 'Verify Expert',
  },
  DOWNLOAD_IOS: {
    KEY:  'download_ios_app',
    URL:  '/app/ios',
    NAME: 'Download iOS app',
  },
  DOWNLOAD_ANDROID: {
    KEY:  'download_android_app',
    URL:  '/app/android',
    NAME: 'Download Android app',
  },
  ARTICLE: {
    KEY:  'article',
    URL:  '/article/:slug',
    NAME: 'Article',
    PARAMS: ['slug'],
  },
  ARTICLE_SUBSCRIPTION: {
    KEY:  'article',
    URL:  '/article-subscription',
    NAME: 'Article Subscription',
  },
  PAYMENT_STATUS: {
    KEY:  'payment',
    URL:  '/payment/:status',
    NAME: 'Payment Status',
  },
}

export {
  ROUTELIST,
};