import { get } from 'lodash';
import thunk from 'redux-thunk';
import { applyMiddleware, compose } from 'redux';
// import { connectRouter, routerMiddleware } from 'connected-react-router'
import logger from './logger';
// import createHistory from 'history/createBrowserHistory';
// import combineReducers from './combineReducers';

// export const history = createHistory()

const initialState = {};
const enhancers: any[] = [];
const middleware: any[] = [thunk];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension: Function | null = get(window, '__REDUX_DEVTOOLS_EXTENSION__', null);
  middleware.push(logger);
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
);

export {
  initialState,
  composedEnhancers,
};
