import React from 'react';
import { Container } from 'react-bootstrap';
import { HelmetTitle } from 'common/libs/helmet';
import { API_URL } from 'common/constants/env';
import { ROUTELIST } from 'routes';
import Layout from 'components/Layout';
import Contents from './RegisterByLinkedIn.contents';
import styles from './RegisterByLinkedIn.module.scss';

export default class RegisterByLinkedIn extends React.PureComponent {
  interval: any = null;

  componentWillMount() {
    (window as any).baseApiUrl = API_URL;
  }

  componentDidMount() {
    import("common/libs/iframe").then(iframeLibs => {
      this.interval = iframeLibs.actionUpdateIframeSize('#register_by_linkedin_frame');
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <React.Fragment>
        <HelmetTitle title={ROUTELIST.EXPERT_REGISTRATION_BY_LINKEDIN.NAME} />
        <Layout>
          <Container fluid className={styles.wrapper}>
            <Contents />
          </Container>
        </Layout>
      </React.Fragment>
    )
  }
}
