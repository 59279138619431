import { defineMessages } from 'react-intl';
import { APP_MESSAGES } from 'common/constants/languages';

export default {
  ...defineMessages({
    expertInformation: {
      id: 'page.contactXpersity.expertInformation',
      defaultMessage: 'Expert Information',
    },
    yourContactInformation: {
      id: 'page.contactXpersity.yourContactInformation',
      defaultMessage: 'Your Contact Information',
    },
    yourPreferredSchedule: {
      id: 'page.contactXpersity.yourPreferredSchedule',
      defaultMessage: 'Your Preferred Schedule',
    },
    yourAdviceNeeds: {
      id: 'page.contactXpersity.yourAdviceNeeds',
      defaultMessage: 'Your Advice Needs & Questions',
    },
    expertName: {
      id: 'page.contactXpersity.expertName',
      defaultMessage: 'Expert Name',
    },
    successMessageTitle: {
      id: 'page.contactXpersity.successMessage.title',
      defaultMessage: 'Thank you for contact Xpersity',
    },
    successMessageDescription: {
      id: 'page.contactXpersity.successMessage.description',
      defaultMessage: 'We will get back to you in the next 24 hours',
    },
    errorMessageTitle: {
      id: 'page.contactXpersity.errorMessage.title',
      defaultMessage: 'Sorry for the inconvenience',
    },
  }),
  errorMessageDescription: APP_MESSAGES.errorDefault,
  sendButton: APP_MESSAGES.send,
  sendingButton: APP_MESSAGES.sending,
};