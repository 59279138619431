import { Props, State } from './Layout.models';
import styles from './Layout.module.scss';

export const DEFAULT_BRAND_LOGO_MARGIN_TOP: number =
  parseInt(styles.brandLogoMarginTop.replace('px', ''), 10);

export const INIT_PROPS: Props = Object.freeze({
  children:             null,
  contentClass:         '',
  containerClass:       '',
  noHorizontalPadding:  false,
});

export const INIT_STATE: State = Object.freeze({
  isScrollDown:       false,
  brandLogoMarginTop: DEFAULT_BRAND_LOGO_MARGIN_TOP,
  backdropOpacity:    0,
});
