import React from 'react';
import { MessageDescriptor, FormattedMessage } from 'react-intl';

const INIT_DEFAULT_VALUES = Object.freeze({
  'br': '',
  'br-xs': '',
  'br-sm': '',
  'br-md': '',
  'br-lg': '',
  'br-xl': '',
});

export const getBlankValues = () => INIT_DEFAULT_VALUES;
export const getDefaultValues = () => ({
  ...INIT_DEFAULT_VALUES,
  'br-xs': React.createElement('br', {className: 'd-none d-inline d-sm-none'}),
  'br-sm': React.createElement('br', {className: 'd-none d-sm-inline d-md-none'}),
  'br_md': React.createElement('br', {className: 'd-none d-md-inline d-lg-none'}),
  'br-lg': React.createElement('br', {className: 'd-none d-lg-inline d-xl-none'}),
  'br-xl': React.createElement('br', {className: 'd-none d-xl'}),
});

export const AutoFormatMessage = (props: {message: MessageDescriptor, additionalValues?: Object}): React.ReactElement => {
  return React.createElement(
    FormattedMessage,
    {
      ...props.message,
      values: {
        ...getDefaultValues(),
        ...(props.additionalValues || {})
      },
    }
  );
};
