import React from 'react';
import { Props } from './Section.models';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { INIT_PROPS } from './Section.constants';
import styles from './Section.module.scss';

class Section extends React.Component<Props & WrappedComponentProps> {
  static defaultProps: Props = INIT_PROPS;
  render() {
    const { formatMessage } = this.props.intl;
    return (
      <div className={styles.wrapper}>
        <h5 className={styles.title}>{ `${this.props.no}. ${formatMessage(this.props.title) as string}` }</h5>
        <div className={styles.container}>
          { this.props.children }
        </div>
      </div>
    )
  }
}

export default injectIntl(Section);