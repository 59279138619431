import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { StoreStatusTypes } from 'common/constants/store';
import { actionUpdateSubscription } from 'store/subscription';
import { StateFromStore } from 'store/models';
import { HelmetTitle } from 'common/libs/helmet';

import { Layout, BasicTextLoading } from 'components';

import {
  Success, Cancel,
} from './Payment.views';
import { Props, OwnActions } from './Payment.models';
import { INIT_PROPS } from './Payment.constants';
import styles from './Payment.module.scss';

class Payment extends React.Component<Props> {
  static defaultProps: Props = INIT_PROPS;

  componentDidMount() {
    const status = get(this.props, 'match.params.status', '');
    const params = new URLSearchParams(this.props.location.search);
    const request = {
      status,
      email: params.get('email'),
      sessionId: params.get('session_id'),
    }
    if (this.props.doUpdateSubscription) {
      this.props.doUpdateSubscription(request);
    }
  }

  renderContent = () => {
    if (this.props.isUpdatingSubscription) {
      return <BasicTextLoading />
    } else if (this.props.updateSubscriptionStatus === StoreStatusTypes.Success) {
      return <Success />
    } else if (this.props.updateSubscriptionStatus === StoreStatusTypes.Error) {
      return <Cancel />
    }

    return null;
  }

  render() {
    return (
      <React.Fragment>
        <HelmetTitle title="404" />
        <Layout>
          <Container className={styles.wrapper}>
            { this.renderContent() }
          </Container>
        </Layout>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: StateFromStore) => ({
  isUpdatingSubscription:         state.subscription.isUpdatingSubscription,
  updateSubscriptionStatus:       state.subscription.updateSubscriptionStatus,
  updateSubscriptionErrorMessage: state.subscription.updateSubscriptionErrorMessage,
});

const mapDispatchToProps = (dispatch): OwnActions => ({
  doUpdateSubscription: (request: any) => dispatch(actionUpdateSubscription(request)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Payment));
