// import STORE_BADGES from 'common/assets/store-badges';
// import { ROUTELIST } from 'routes';
// import { Locale } from 'types/locale';
import { Props } from './ArticleUnit.models';

export const INIT_PROPS: Props = Object.freeze({
  article: {},
  // 
  onClick: null,
} as Props);

// export const DOWNLOAD_LINK = {
//   [DownloadType.apple]:   ROUTELIST.DOWNLOAD_IOS.URL,
//   [DownloadType.google]:  ROUTELIST.DOWNLOAD_ANDROID.URL,
// }