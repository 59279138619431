import React from 'react';
import { injectIntl, useIntl } from 'react-intl';
import {
  globalConnection as imgGlobalConnection,
} from 'common/assets';
import { getBlankValues } from 'common/libs/intl';
import messages from './ArticleSubscription.messages';
import styles from './ArticleSubscription.module.scss';

export const Image = () => {
  const intl = useIntl();
  return (
    <img
      className={styles.featureImg}
      src={imgGlobalConnection}
      alt={ intl.formatMessage(messages.title, getBlankValues()) as string }
    />
  );
}

export const ButtonSubmittingContent = injectIntl(({intl}) => (
  <React.Fragment>
    <i className="fas fa-spinner fa-pulse mr-1" />
    <span className="d-inline-block capitalize-first-letter">
      { intl.formatMessage(messages.submittingButton) }
    </span>
  </React.Fragment>
));

export const ButtonCheckoutContent = () => (
  <React.Fragment>
    <i className="fas fa-paper-plane mr-1" />
    <span className="d-inline-block capitalize-first-letter">
      Checkout
    </span>
  </React.Fragment>
);

export const ButtonSubmitContent = injectIntl(({intl}) => (
  <React.Fragment>
    <i className="fas fa-paper-plane mr-1" />
    <span className="d-inline-block capitalize-first-letter">
      { intl.formatMessage(messages.submitButton) }
    </span>
  </React.Fragment>
));

export const SuccessMessage = injectIntl(({intl}) => (
  <React.Fragment>
    <h6 className="text-center mb-2">{ intl.formatMessage(messages.successMessageTitle) }</h6>
    <p className="text-center mb-0">{ intl.formatMessage(messages.successMessageDescription) }</p>
  </React.Fragment>
));

export const ErrorMessage = injectIntl(({intl}) => (
  <React.Fragment>
    <h6 className="text-center mb-2">{ intl.formatMessage(messages.errorMessageTitle) }</h6>
    <p className="text-center text-danger mb-0">{ intl.formatMessage(messages.errorMessageDescription) }</p>
  </React.Fragment>
));