import React from 'react';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { HelmetTitle } from 'common/libs/helmet';
import { getFormattedTime } from 'common/libs/moment';
import { StoreStatusTypes } from 'common/constants/store';
import { Container } from 'react-bootstrap';
import { StateFromStore } from 'store/models';
import { actionGetArticle } from 'store/article';
import { Layout, BasicTextLoading } from 'components';
import Header from './Header';
import { NoContentAvailable } from './ArticleDetail.views';
import { getArticleIDFromSlug } from './ArticleDetail.utils';
import { Props, OwnActions } from './ArticleDetail.models';
import { INIT_PROPS } from './ArticleDetail.constants';
import styles from './ArticleDetail.module.scss';

class ArticleDetail extends React.Component<Props> {
  static defaultProps = INIT_PROPS

  componentDidMount = () => {
    const slug = get(this.props, 'match.params.slug', '');
    const id = getArticleIDFromSlug(slug);
    if (id) {
      this.props.doGetArticle(id);
    }
  }

  renderArticleDetail = () => {
    const { article } = this.props;
    return (
      <React.Fragment>
        <h1 className={styles.title}>{ article.title }</h1>
        <p className={styles.moreInfo}>
          <span>
            Published on { getFormattedTime(article.publishedAt) } by <span className={styles.author}>{ article.creator }</span>
          </span>
        </p>
        <hr />
        <div className={styles.content} dangerouslySetInnerHTML={{__html: article.content}} />
      </React.Fragment>
    )
  }

  renderContent = () => {
    const { isGettingArticleDetail, getArticleDetailStatus, article } = this.props;
    if (isGettingArticleDetail) {
      return (<BasicTextLoading />);
    } else if (getArticleDetailStatus === StoreStatusTypes.Error) {
      return (<NoContentAvailable />);
    } else if (!isGettingArticleDetail && !isEmpty(article)) {
      return this.renderArticleDetail();
    }
    return '';
  }

  render() {
    return (
      <React.Fragment>
        { this.props.article.title && (<HelmetTitle title={this.props.article.title} />) }
        <Layout>
          <Container className={styles.wrapper}>
            <Header />
            { this.renderContent() }
          </Container>
        </Layout>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: StateFromStore) => ({
  article:                      state.article.article,
  isGettingArticleDetail:       state.article.isGettingArticleDetail,
  getArticleDetailStatus:       state.article.getArticleDetailStatus,
  getArticleDetailErrorMessage: state.article.getArticleDetailErrorMessage,
});

const mapDispatchToProps = (dispatch): OwnActions => ({
  doGetArticle: (id: string) => dispatch(actionGetArticle(id)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(ArticleDetail)));