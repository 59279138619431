import { Props, State, SubscribeInfoData, SubscribeInfoError } from './FormInformation.models';

export const INIT_PROPS: Props = Object.freeze({
  disabled: false,
  onChange: () => {},
  onError: () => {},
} as Props);

export const INIT_FORM_DATA: SubscribeInfoData = Object.freeze({
  firstName:  '',
  lastName:   '',
  email:      '',
} as SubscribeInfoData);

export const INIT_FORM_ERROR: SubscribeInfoError = Object.freeze({
  firstName:  '',
  lastName:   '',
  email:      '',
} as SubscribeInfoError);

export const INIT_STATE: State = Object.freeze({
  formData: INIT_FORM_DATA,
  formError: INIT_FORM_ERROR,
} as State);