import React from 'react';
import classnames from 'classnames';
import { Image, Badge, Button } from 'react-bootstrap';
import { ButtonContactXpersity } from 'components';
import { APP_MESSAGES } from 'common/constants/languages';
import { AutoFormatMessage } from 'common/libs/intl';
import { Props } from './ExpertShortDetail.models';
import { INIT_PROPS, DEFAULT_IMAGE } from './ExpertShortDetail.constants';
import styles from './ExpertShortDetail.module.scss';

export default class ExpertShortDetail extends React.PureComponent<Props> {
  static defaultProps: Props = INIT_PROPS;

  handleToViewDetail = () => {
    this.props.onClick();
  }

  renderPicture = () => {
    const { name, profilePicture } = this.props.expertDetail;
    let imageAttr: {[key: string]: any} = {};
    if (profilePicture.length > 0) {
      imageAttr['style'] = { backgroundImage: `url(${profilePicture})` };
    }
    return (
      <div className={classnames([styles.pictureWrapper, 'rounded'])}>
        <Image
          rounded
          src={DEFAULT_IMAGE}
          className={styles.picture}
          {...imageAttr}
        />
        <div className={styles.pictureFirstChar}>
          <span>{name.slice(0,1)}</span>
        </div>
      </div>
    );
  };

  renderViewDetailButton = () => (
    <Button
      size="sm"
      variant="link"
      className={classnames([styles.buttonViewDetail])}
      onClick={() => this.handleToViewDetail()}
    >
      <AutoFormatMessage message={APP_MESSAGES.viewDetail} />
      <i className="fas fa-long-arrow-alt-right ml-1" />
    </Button>
  )

  renderLocations = () => {
    const { expertDetail } = this.props;
    return (
      <p className={styles.location}>
        <small>
          <i className="fas fa-map-marker-alt text-primary" /> { expertDetail.location }
        </small>
      </p>
    );
  }

  renderHashtags = () => {
    const { expertDetail } = this.props;
    return (
      <div className={styles.expertises}>
        { expertDetail.hashtags.map((e, i) => this.renderBadge(expertDetail.id, e, i)) }
      </div>
    );
  }

  renderBadge = (expertId: string, expertise: string, index: number) => {
    const key = `${expertId}-expertise-${index}`;
    return (<Badge key={key} className={styles.expertise} variant="light">{ expertise }</Badge>);
  }

  render() {
    const {
      expertDetail:   detail,
      className:      classWrapper,
      hideReadMore:   isHideReadMore,
      hideExpertises: isHideExpertises,
      hideLocations:  isHideLocations,
    } = this.props;
    return (
      <div className={classnames([styles.wrapper, classWrapper])}>
        <div className={styles.side}>
          { this.renderPicture() }
        </div>
        <div className={styles.header}>
          <h4 className={styles.name}>
            { detail.name }
            { !isHideReadMore && this.renderViewDetailButton() }
          </h4>
          { !isHideLocations && this.renderLocations() }
          <ButtonContactXpersity
            size="sm"
            expertName={detail.name}
            className={styles.buttonContact}
          />
        </div>
        <div className={styles.main}>
          <hr className={styles.divider} />
          <div className={styles.introduction}>
            <p className="mb-1">{ detail.expertiseDescription }</p>
            <p className={styles.introduction__experiences}>
              <AutoFormatMessage message={APP_MESSAGES.experiences} />: { detail.companies }
            </p>
          </div>
          { !isHideExpertises && this.renderHashtags() }
        </div>
      </div>
    )
  }
}
