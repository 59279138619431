import { defineMessages } from 'react-intl';

export default {
  ...defineMessages({
    'adviceNeeds[0]': {
      id: 'page.contactXpersity.adviceNeeds[0]',
      defaultMessage: 'Career Advice',
    },
    'adviceNeeds[1]': {
      id: 'page.contactXpersity.adviceNeeds[1]',
      defaultMessage: 'Consulting & Professional Advisory',
    },
    'adviceNeeds[2]': {
      id: 'page.contactXpersity.adviceNeeds[2]',
      defaultMessage: 'Short-Term Project',
    },
    messagePlaceHolder: {
      id: 'page.contactXpersity.yourMessage.placeHolder',
      defaultMessage: 'Your message here... please be as specific as possible and include the expert name (if selected from the list).',
    },
    messageNotice: {
      id: 'page.contactXpersity.yourMessage.notice',
      defaultMessage: 'Maximum length of 1000 characters',
    }
  }),
};