import { defineMessages } from 'react-intl';

export default {
  ...defineMessages({
    expertises: {
      id: 'component.expertDetail.groupTitle.expertises',
      defaultMessage: 'Domain/Subject Expertise',
    },
    marketExpertise: {
      id: 'component.expertDetail.groupTitle.marketExpertise',
      defaultMessage: 'Market Expertise',
    },
    experiences: {
      id: 'component.expertDetail.groupTitle.experiences',
      defaultMessage: 'Experience',
    },
    education: {
      id: 'component.expertDetail.groupTitle.education',
      defaultMessage: 'Education',
    },
    languages: {
      id: 'component.expertDetail.groupTitle.languages',
      defaultMessage: 'Language',
    },
    media:  {
      id: 'component.expertDetail.groupTitle.media',
      defaultMessage: 'Media',
    },
  }),
};
