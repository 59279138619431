import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTELIST } from 'routes';
import { Container, Row, Col, Button } from 'react-bootstrap';
import * as Views from './SectionDecisions.views';
import styles from './SectionDecisions.module.scss';

export default class SectionDecisions extends React.Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.backdrop}>
          <Row noGutters className="flex-grow-1">
            <Col className={styles.backdropLeft} xs="7">&nbsp;</Col>
            <Col className={styles.backdropRight} xs="4">&nbsp;</Col>
          </Row>
        </div>
        <Container className={styles.container}>
          <Row className="align-content-stretch">
            <Col className={styles.containerLeft} xs="12" md="7">
              <div className={styles.content}>
                <Views.Title />
                <Views.Description />
              </div>
            </Col>
            <Col className={styles.containerRight} xs="12" md="4">
              <div className="d-flex w-100 h-100 align-items-center justify-content-center">
                <Button
                  size="lg"
                  as={Link}
                  variant="outline-primary"
                  to={ROUTELIST.EXPERTS.URL}
                  className={styles.buttonFindYourExpert}
                >
                  <Views.TextFindExpert />
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}
