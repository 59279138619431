import React from 'react';
import { Container } from 'react-bootstrap';
import { HelmetTitle } from 'common/libs/helmet';
import { BasicTextLoading } from 'components/LoadingComponents';
import { API_URL } from 'common/constants/env';
import { ROUTELIST } from 'routes';
import Layout from 'components/Layout';
import styles from './RegisterByEmail.module.scss';

const Contents = React.lazy(() => import('./RegisterByEmail.contents'));

export default class RegisterByEmail extends React.PureComponent {
  interval: any = null;
  componentWillMount() {
    (window as any).baseApiUrl = API_URL;
  }

  componentDidMount() {
    import("common/libs/iframe").then(iframeLibs => {
      this.interval = iframeLibs.actionUpdateIframeSize('#register_by_email_frame');
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <React.Fragment>
        <HelmetTitle title={ROUTELIST.EXPERT_REGISTRATION_BY_EMAIL.NAME} />
        <Layout>
          <Container fluid className={styles.wrapper}>
            <React.Suspense fallback={<BasicTextLoading />}>
              <Contents />
            </React.Suspense>
          </Container>
        </Layout>
      </React.Fragment>
    )
  }
}
