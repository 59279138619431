import React from 'react';
import classnames from 'classnames';
import Images from 'common/assets/store-badges';
import { Props } from './BadgeDownload.models';
import { INIT_PROPS, DOWNLOAD_LINK } from './BadgeDownload.constants';
import styles from './BadgeDownload.module.scss';

export default class BadgeDownload extends React.PureComponent<Props> {
  static defaultProps: Props = INIT_PROPS;
  render() {
    const {
      locale,
      className: wrapperClass,
      downloadType,
    } = this.props;
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={DOWNLOAD_LINK[downloadType]}
        className={classnames([styles.wrapper, wrapperClass])}
      >
        <img
          alt="Download"
          className={styles.badge}
          src={Images[locale][downloadType]}
        />
      </a>
    )
  }
}
