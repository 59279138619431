import { defineMessages } from 'react-intl';
import { APP_MESSAGES } from 'common/constants/languages';

export default defineMessages({
  title: {
    id: 'page.articleSubscription.title',
    defaultMessage: "Subscription",
  },
  submitButton: APP_MESSAGES.submit,
  submittingButton: APP_MESSAGES.submitting,
  successMessageTitle: {
    id: 'page.articleSubscription.successMessage.title',
    defaultMessage: 'Request successful!',
  },
  successMessageDescription: {
    id: 'page.articleSubscription.successMessage.description',
    defaultMessage: 'You will receive this free report in your email from us',
  },
  errorMessageTitle: {
    id: 'page.articleSubscription.errorMessage.title',
    defaultMessage: 'Sorry for the inconvenience',
  },
  errorMessageDescription: APP_MESSAGES.errorDefault,
})
