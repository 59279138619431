import React from 'react';
import { AutoFormatMessage } from 'common/libs/intl';
import MESSAGES from './PaidArticleDetail.messages';

export const NoContentAvailable = () => (
  <div className="d-block mb-5">
    <p className="text-center text-secondary font-weight-light" style={{fontSize: '1.5rem'}}>
      <AutoFormatMessage message={MESSAGES.noContentAvailable} />
    </p>
  </div>
);