import React from 'react';
import { AutoFormatMessage } from 'common/libs/intl';
import messages from './Header.messages';
import styles from './Header.module.scss';

export default class Header extends React.Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <h1 className="mb-0 text-center text-md-left text-capitalize">
          <AutoFormatMessage message={messages.title} />
        </h1>
        <p className="text-center text-md-left">
          <small><AutoFormatMessage message={messages.description} /></small>
        </p>
      </div>
    )
  }
}
