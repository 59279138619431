import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import { AutoFormatMessage } from 'common/libs/intl';
import styles from './Navigation.module.scss';

export const NavigationWrapper = ({className, controlId, children}) => (
  <div className={className}>
    <Navbar.Toggle aria-controls={controlId} />
    <Navbar.Collapse id={controlId} className={styles.collapse}>
      <Nav className={styles.nav}>{ children }</Nav>
    </Navbar.Collapse>
  </div>
);

export const NavLink = ({key, className, message, to}) => (
  <Nav.Link as={Link} {...{key, className, to}}>
    <AutoFormatMessage message={message} />
  </Nav.Link>
);

export const NavExternalLink = ({key, className, message, href}) => (
  <a {...{key, className, href}} target="_blank" rel="noopener noreferrer">
    <AutoFormatMessage message={message} />
  </a>
);