// import STORE_BADGES from 'common/assets/store-badges';
import { ROUTELIST } from 'routes';
import { Locale } from 'types/locale';
import { DownloadType, Props } from './BadgeDownload.models';

export const INIT_PROPS: Props = Object.freeze({
  className:    '',
  locale:       Locale.en,
  downloadType: DownloadType.apple,
});

export const DOWNLOAD_LINK = {
  [DownloadType.apple]:   ROUTELIST.DOWNLOAD_IOS.URL,
  [DownloadType.google]:  ROUTELIST.DOWNLOAD_ANDROID.URL,
}