// import { ActionStates, ActionStore } from 'models/Redux';
import { actionCallAPI } from 'common/libs/action';
import { ActionTypes } from './contact.models';
import * as contactServices from 'services/contact';

export const actionContactXpersity = (data: object) => (dispatch) => {
  const actionName = ActionTypes.ContactXpersity;
  const asyncAction = contactServices.contactXpersity(data);
  const actions = {
    success: async (response) => {
      if (response.status === 200) {
        return {};
      }
      return null;
    },
    error: (response) => {
      const error = response.status;
      return error || response.data.error;
    }
  };

  return dispatch(actionCallAPI(actionName, asyncAction, actions));
};