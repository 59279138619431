import { StoreStatusTypes } from 'common/constants/store';

export enum ActionTypes {
  VerifyUser = 'VERIFY_USER',
  VerifyExpert = 'VERIFY_EXPERT',
};

// 
export interface StoreVerifyAccount {
  isVerifingAccount:          boolean,
  verifyAccountStatus:        StoreStatusTypes,
  verifyAccountErrorMessage:  '',
}

export interface State extends
StoreVerifyAccount
{}