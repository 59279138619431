// import { ActionStates, ActionStore } from 'models/Redux';
import { get, has } from 'lodash';
import papaParse from 'papaparse';
import { buildListExpertFromGsheetData } from 'common/libs/gsheet';
import { actionCallAPI } from 'common/libs/action';
import { ActionTypes } from './gsheet.models';
// import { ActionStore } from 'models/Store';
// import { ActionStates } from 'common/constants/store';
import * as gsheetServices from 'services/gsheet';
import { ListExperts } from './gsheet.models';

export const actionGetListExperts = () => async (dispatch) => {
  const actionName = ActionTypes.GetListExperts;
  const asyncAction = gsheetServices.getListExpertsFromBackup();
  const actions = {
    success: async (response) => {
      if (response.status === 200 && has(response, 'data')) {
        const dataParsed = await papaParse.parse(response.data, { header: true });
        return {
          experts: buildListExpertFromGsheetData(get(dataParsed, 'data', [])),
          expertsUpdatedAt: (new Date()).toString(),
        } as ListExperts;
      }
      return null;
    },
    error: (response) => {
      const error = response.status;
      return error || response.data.error;
    }
  };

  return dispatch(actionCallAPI(actionName, asyncAction, actions));
};

// export const actionGetListExperts = () => (dispatch) => {
//   const actionName = ActionTypes.GetListExperts;
//   const asyncAction = gsheetServices.getListExperts();
//   const actions = {
//     success: async (response) => {
//       let csvData = '';
//       if (response.status === 200
//       && has(response, 'data.data')
//       && !isEmpty(response.data.data)
//       && !response.data.data.includes('Loading...')
//       && !response.data.data.includes('#NAME?')
//       ) {
//         csvData = response.data.data;
//       } else {
//         await gsheetServices.getListExpertsFromBackup().then((secondRes) => {
//           if (secondRes.status === 200 && has(secondRes, 'data') && !isEmpty(secondRes.data)) {
//             csvData = secondRes.data;
//           }
//         });
//       }

//       if (csvData.length > 0) {
//         const dataParsed = await papaParse.parse(csvData, { header: true });
//         return {
//           experts: buildListExpertFromGsheetData(get(dataParsed, 'data', [])),
//           expertsUpdatedAt: (new Date()).toString(),
//         } as ListExperts;
//       }

//       return null;
//     },
//     error: (response) => {
//       const error = response.status;
//       return error || response.data.error;
//     }
//   };

//   return dispatch(actionCallAPI(actionName, asyncAction, actions));
// };