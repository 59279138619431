import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './SectionHero.module.scss';
import * as Views from './SectionHero.views';

class SectionHero extends React.Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <Row className="align-items-center">
          <Col xs="12" md="6" className="text-center text-md-left">
            <div className={styles.content}>
              <Views.Title />
              <Views.Description />
            </div>
          </Col>
          <Col xs="12" md="6">
            <Views.Image />
          </Col>
        </Row>
      </div>
    )
  }
}

export default SectionHero;