import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'page.experts.header.title',
    defaultMessage: 'Meet our experts',
  },
  description: {
    id: 'page.experts.header.description',
    defaultMessage: 'Your access to experts is just a few click away',
  }
})