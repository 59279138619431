import { StoreStatusTypes } from 'common/constants/store';

export enum ActionTypes {
  GetProducts = 'GET_PRODUCTS',
  SubscribeForFreeReport = 'SUBSCRIBE_FOR_FREE_REPORT',
  SubscribeProduct = 'SUBSCRIBE_PRODUCT',
  UpdateSubscription = 'UPDATE_SUBSCRIPTION',
};

//
export interface StoreGetProducts {
  isGettingProducts:        boolean,
  getProductsStatus:        StoreStatusTypes,
  getProductsErrorMessage:  string,
  products:                 Array<any>,
}

// 
export interface StoreSubscribeForFreeReport {
  isSubscribeForFreeReport:           boolean,
  subscribeForFreeReportStatus:       StoreStatusTypes,
  subscribeForFreeReportErrorMessage: string,
}

export interface StoreSubscribeProduct {
  isSubscribingProduct:         boolean,
  subscribeProductStatus:       StoreStatusTypes,
  subscribeProductErrorMessage: string,
  subscribeSessionId:           string,
  subscribeStripePublicKey:     string,
}

export interface StoreUpdateSubscription {
  isUpdatingSubscription:         boolean,
  updateSubscriptionStatus:       StoreStatusTypes,
  updateSubscriptionErrorMessage: string,
}

export interface State extends
StoreGetProducts,
StoreSubscribeProduct,
StoreSubscribeForFreeReport,
StoreUpdateSubscription
{}