import { getReducerData } from 'common/libs/reducer';
import { ActionStore, ReducerKeyMapping } from 'models/Store';
import { ActionTypes, State } from './subscription.models';
import { INIT_STATE } from './subscription.constants';

export default (state: State = INIT_STATE, action: ActionStore) => {
  switch (action.type) {
    case ActionTypes.GetProducts:
      return getReducerData(state, action, {
        loading:      'isGettingProducts',
        statusType:   'getProductsStatus',
        errorMessage: 'getProductsErrorMessage',
      } as ReducerKeyMapping);

    case ActionTypes.SubscribeForFreeReport:
      return getReducerData(state, action, {
        loading:      'isSubscribeForFreeReport',
        statusType:   'subscribeForFreeReportStatus',
        errorMessage: 'subscribeForFreeReportErrorMessage',
      } as ReducerKeyMapping);

    case ActionTypes.SubscribeProduct:
      return getReducerData(state, action, {
        loading:      'isSubscribingProduct',
        statusType:   'subscribeProductStatus',
        errorMessage: 'subscribeProductErrorMessage',
      } as ReducerKeyMapping);

    case ActionTypes.UpdateSubscription:
      return getReducerData(state, action, {
        loading:      'isUpdatingSubscription',
        statusType:   'updateSubscriptionStatus',
        errorMessage: 'updateSubscriptionErrorMessage',
      } as ReducerKeyMapping);
    default:
      return state;
  }
};
