import React from 'react';
import classnames from 'classnames';
import {
  NAV_LINKS,
  INIT_PROPS,
  NAVIGATION_KEY,
} from './Navigation.constants';
import * as Views from './Navigation.views';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Props } from './Navigation.models';
import styles from './Navigation.module.scss';

class Navigation extends React.PureComponent<Props & WrappedComponentProps> {
  static defaultProps: Props = INIT_PROPS;
  renderNavLink = () => {
    const { locale } = this.props.intl;
    return NAV_LINKS.map(o => {
      if (!o.locale_restrict.includes(locale)) {
        if (o.is_external) {
          return Views.NavExternalLink({
            key: o.key,
            href: o.url,
            message: o.message,
            className: classnames(['nav-link', styles.navLink]),
          });
        }
        return Views.NavLink({
          to: o.url,
          key: o.key,
          message: o.message,
          className: classnames([styles.navLink]),
        });
      }
      
      return null;
    });
  }

  render() {
    return Views.NavigationWrapper({
      controlId:  NAVIGATION_KEY,
      children:   this.renderNavLink(),
      className:  classnames([styles.wrapper, this.props.className]),
    });
  }
}

export default injectIntl(Navigation);