import { defineMessages } from 'react-intl';
import { APP_MESSAGES } from 'common/constants/languages';

export default {
  firstName: APP_MESSAGES.firstName,
  lastName: APP_MESSAGES.lastName,
  email: APP_MESSAGES.email,
  ...defineMessages({
    preferredLanguage: {
      id: 'page.contactXpersity.preferredLanguage',
      defaultMessage: 'Preferred Language',
    },
  }),
};