import { INIT_LANGUAGE } from 'store/language/language.constants';
import { OwnActions, Props } from './LanguageSwitcher.models';
import { Locale } from 'types/locale';

export const INIT_OWN_ACTIONS: OwnActions = Object.freeze({
  doUpdateLocale: (lang: Locale) => {},
} as OwnActions);

export const INIT_PROPS: Props = Object.freeze({
  ...INIT_LANGUAGE,
  ...INIT_OWN_ACTIONS,
  className: '',
} as Props);
