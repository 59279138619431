import { actionCallAPI } from 'common/libs/action';
import { get } from 'lodash';
import * as subscriptionServices from 'services/subscription';
import { ActionTypes } from './subscription.models';

export const actionGetProducts = () => (dispatch) => {
  const actionName = ActionTypes.GetProducts;
  const asyncAction = subscriptionServices.getProducts();
  const actions = {
    success: async (response) => {
      if (get(response, 'status', 0) === 200 && get(response, 'data.data', false)) {
        return {
          products: get(response, 'data.data', []),
        };
      }
      return { products: [] };
    },
    error: (response) => {
      const error = response.status;
      return error || response.data.error;
    }
  };

  return dispatch(actionCallAPI(actionName, asyncAction, actions));
}

export const actionSubscribeForFreeReport = (data: object) => (dispatch) => {
  const actionName = ActionTypes.SubscribeForFreeReport;
  const asyncAction = subscriptionServices.subscribeForFreeReport(data);
  const actions = {
    success: async (response) => {
      if (get(response, 'status', 0) === 200 && get(response, 'data.data', false)) {
        return {};
      }
      return null;
    },
    error: (response) => {
      const error = response.status;
      return error || response.data.error;
    }
  };

  return dispatch(actionCallAPI(actionName, asyncAction, actions));
};

export const actionSubscribeProduct = (data: object) => (dispatch) => {
  const actionName = ActionTypes.SubscribeProduct;
  const asyncAction = subscriptionServices.subscribeProduct(data);
  const actions = {
    success: async (response) => {
      if (get(response, 'status', 0) === 200 && get(response, 'data.data', false)) {
        return {
          subscribeSessionId: get(response, 'data.data.id', false),
          subscribeStripePublicKey: get(response, 'data.data.stripe_key', false),
        };
      }
      return null;
    },
    error: (response) => {
      const error = response.status;
      return error || response.data.error;
    }
  };

  return dispatch(actionCallAPI(actionName, asyncAction, actions));
};

export const actionUpdateSubscription = (data: object) => (dispatch) => {
  const actionName = ActionTypes.UpdateSubscription;
  const asyncAction = subscriptionServices.updateSubscription(data);
  const actions = {
    success: async (response) => {
      if (get(response, 'status', 0) === 200 && get(response, 'data.data', false)) {
        return {};
      }
      return null;
    },
    error: (response) => {
      const error = response.status;
      return error || response.data.error;
    }
  };

  return dispatch(actionCallAPI(actionName, asyncAction, actions));
};