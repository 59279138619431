import { combineReducers } from 'redux';
import * as contact from './contact';
import * as gsheet from './gsheet';
import * as user from './user';
import * as language from './language';
import * as article from './article';
import * as subscription from './subscription';

export default combineReducers({
  [contact.MODULE_NAME]: contact.reducers,
  [gsheet.MODULE_NAME]: gsheet.reducers,
  [user.MODULE_NAME]: user.reducers,
  [language.MODULE_NAME]: language.reducers,
  [article.MODULE_NAME]: article.reducers,
  [subscription.MODULE_NAME]: subscription.reducers,
});
