import { AdviceNeedsData } from '../RequestForm.models';
import { Props, State } from './AdviceNeeds.models';
import messages from './AdviceNeeds.messages';

export const LIST_CHECKBOXES = [
  messages["adviceNeeds[0]"],
  messages["adviceNeeds[1]"],
  messages["adviceNeeds[2]"],
];

export const ON_CHANGED_TIMEOUT = 1000;

export const INIT_PROPS: Props = Object.freeze({
  onChanged: () => {},
} as Props);

export const INIT_DATA: AdviceNeedsData = Object.freeze({
  adviceNeeds:  [],
  message:      '',
} as AdviceNeedsData);

export const INIT_STATE: State = Object.freeze({
  ...INIT_DATA,
  selectedIndexes: [],
} as State);