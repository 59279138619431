import { ContactInfoData } from '../RequestForm.models';
import { Props, State } from './ContactInfo.models';

export const ON_CHANGED_TIMEOUT = 1000;

export const INIT_PROPS: Props = Object.freeze({
  onChanged: () => {},
} as Props);

export const INIT_DATA: ContactInfoData = Object.freeze({
  firstName:          '',
  lastName:           '',
  email:              '',
  preferredLanguage:  '',
} as ContactInfoData);

export const INIT_STATE: State = Object.freeze({
  ...INIT_DATA,
} as State);