import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar } from 'react-bootstrap';
import { BrandLogo, LanguageSwitcher } from 'components';
import Navigation from '../Navigation';
import { INIT_PROPS } from './Header.constants';
import { Props } from './Header.models';
import styles from './Header.module.scss';

export default class Header extends React.Component<Props> {
  static defaultProps: Props = INIT_PROPS;
  render() {
    return (
      <Navbar className={styles.wrapper} fixed="top" expand="lg">
        <div className={styles.backdrop} style={{opacity: this.props.backdropOpacity}}>&nbsp;</div>
        <Container className={styles.container}>
          <Navbar.Brand
            to="/"
            as={Link}
            className={styles.brandLogo}
            style={{marginTop: this.props.brandLogoMarginTop}}
          >
            <BrandLogo />
          </Navbar.Brand>
          <LanguageSwitcher className="order-1 order-lg-2 mr-auto mr-md-0" />
          <Navigation className="order-2 order-lg-1" />
        </Container>
      </Navbar>
    )
  }
}
