import React from 'react';
import { Container } from 'react-bootstrap';
import { HelmetTitle } from 'common/libs/helmet';
import { BasicTextLoading } from 'components/LoadingComponents';
import { ROUTELIST } from 'routes';
import Layout from 'components/Layout';
import { Header } from './Terms.views';
import styles from './Terms.module.scss';

const Contents = React.lazy(() => import('./Terms.contents'));

export default class Terms extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <HelmetTitle title={ROUTELIST.TERM.NAME} />
        <Layout>
          <Container className={styles.wrapper}>
            <Header title="Terms of Use" />
            <React.Suspense fallback={<BasicTextLoading />}>
              <Contents />
            </React.Suspense>
          </Container>
        </Layout>
      </React.Fragment>
    )
  }
}
